
  import { defineComponent } from '@vue/runtime-core';
  import { mapActions, mapMutations } from 'vuex';
  import { EyeOffIcon, EyeIcon } from '@heroicons/vue/solid';

  export default defineComponent({
    data() {
      return {
        newPassword: '',
        confirmPassword: '',
        wrongConfirmPassword: false as boolean,
        errors: {} as Record<string, unknown>,
        showNewPassword: false,
      };
    },
    components: { EyeOffIcon, EyeIcon },
    methods: {
      ...mapActions('user', ['resetPassword']),
      ...mapMutations('notification', ['setNotification']),

      validateConfirmPassword() {
        if (this.newPassword != this.confirmPassword) {
          this.wrongConfirmPassword = true;
          this.errors.confirmPassword = 'Password Mismatched!';
        } else {
          this.wrongConfirmPassword = false;
          this.errors.confirmPassword = null;
        }
      },
      validateRequest() {
        this.errors = {};
        if (this.newPassword === '') {
          this.errors.newPassword = 'New Password is Required!';
        }
        if (this.confirmPassword === '') {
          this.errors.confirmPassword = 'Confirm Password is Required!';
        }
        const hasErrors = Boolean(Object.keys(this.errors).length);
        return hasErrors;
      },
      submit() {
        this.validateRequest();
        if (this.$route.query.token) {
          const data = {
            token: this.$route.query.token,
            password: this.newPassword,
            password_confirmation: this.confirmPassword,
            email: this.$route.query.email,
          };
          this.resetPassword(data).then(() => {
            this.setNotification({
              title: 'Password Reseted Successfully!',
              type: 'success',
            });
            this.$router.push('/login');
          });
        }
      },
    },
  });
