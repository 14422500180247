import axios from 'axios';
import snakeToCamel from '@/utils/snakeToCamel';
import Auth from './Auth';
import router from '@/router';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

instance.interceptors.response.use(
  (response) => snakeToCamel(response),
  (error) => {
    if (error.response.status === 401) {
      const auth = new Auth();
      auth.logout();
      router.push('/login');
    } else {
      return Promise.reject(snakeToCamel(error));
    }
  },
);

export default instance;
