
  import { defineComponent } from '@vue/runtime-core';
  import { mapActions, mapState } from 'vuex';
  import Modal from '../../components/Modal.vue';
  import ReviewForm from '../review/ReviewForm.vue';
  import { FilterIcon } from '@heroicons/vue/solid';
  import Dropdown from '../../components/Dropdown.vue';
  import { StarIcon } from '@heroicons/vue/solid';

  export default defineComponent({
    data() {
      return {
        tableActions: [
          {
            label: 'Edit',
            action: 'edit',
            disabled: false,
          },
        ],
        selectedFilter: 'Reviews For Me',
        reviewFormModal: false,
        filterOptions: [
          {
            label: 'Reviews For Me',
            action: 'getReviewsForMe',
            disabled: false,
          },
          {
            label: 'Reviews From Me',
            action: 'getReviewsFromMe',
            disabled: false,
          },
        ],
      };
    },
    setup() {
      return {};
    },
    computed: {
      ...mapState('user', ['user']),
      ...mapState('review', ['userReviews']),
    },
    components: {
      StarIcon,
      Modal,
      ReviewForm,
      FilterIcon,
      Dropdown,
    },
    mounted() {
      if (this.$route.name === 'View Profile') {
        this.getReviewsForSelectedUser(this.$route.params.userId);
      } else {
        this.getReviewsForSelectedUser(this.user.id);
      }
    },
    methods: {
      ...mapActions('review', [
        'getUserReviews',
        'getReview',
        'getReviewsForSelectedUser',
        'getReviewsFromSelectedUser',
      ]),
      formatDate(date: Date) {
        return new Date(date).toISOString().split('T')[0];
      },
      addReview(id: number) {
        if (id) {
          this.editReview({ reviewId: id, userId: this.user.id });
        }
        this.reviewFormModal = true;
      },
      closeModal() {
        this.reviewFormModal = false;
      },
      getReviewsForMe() {
        this.getReviewsForSelectedUser(this.user.id);
        this.selectedFilter = 'Reviews For Me';
      },
      getReviewsFromMe() {
        this.getReviewsFromSelectedUser(this.user.id);
        this.selectedFilter = 'Reviews From Me';
      },
      editReview(id: any) {
        this.getReview({ reviewId: id }).then(() => {
          this.reviewFormModal = true;
        });
      },
    },
  });
