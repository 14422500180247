import axios from '../../../middleware/Axios';
import { RootState } from '@/store';
import { ActionContext } from 'vuex';
import { State } from './index';
import snakeToCamel from '@/utils/snakeToCamel';
import { ssrContextKey } from '@vue/runtime-core';
const actions = {
  getRoles(context: ActionContext<State, RootState>): void {
    axios
      .get('/api/v1/roles')
      .then((response) => {
        context.commit('setRoles', response.data);
      })
      .catch((err) => {
        return err;
      });
  },
  getAllRoleCategories(context: ActionContext<State, RootState>): void {
    axios
      .get(`/api/v1/categories`)
      .then((response) => {
        context.commit('setCategories', response.data.data);
      })
      .catch((err) => {
        return err;
      });
  },
  getRoleCategoriesByRoleId(
    context: ActionContext<State, RootState>,
    payload: number,
  ): void {
    axios
      .get(`/api/v1/roles/${payload}/categories`)
      .then((response) => {
        context.commit('setRoleCategories', snakeToCamel(response.data.data));
      })
      .catch((err) => {
        return err;
      });
  },
};
export default actions;
