
  import { mapActions, mapState, mapMutations } from 'vuex';
  import { defineComponent } from 'vue';
  import Dropdown from '../../components/Dropdown.vue';
  import { capitalize } from '../../utils/camelToReadable';
  import Pagination from '../../components/Pagination.vue';

  export default defineComponent({
    components: {
      Dropdown,
      Pagination,
    },
    data() {
      return {
        isVisible: false,
      };
    },
    computed: {
      ...mapState('admin', ['users', 'userMeta']),
    },
    mounted() {
      this.getUsers();
    },
    methods: {
      ...mapActions('admin', ['getUsers', 'updateUser']),
      ...mapMutations('notification', ['setNotification']),

      getFirstLetterCapital(value: string): string {
        if (value) {
          return capitalize(value);
        } else {
          return '';
        }
      },
      formatDate(date: Date) {
        return new Date(date).toISOString().split('T')[0];
      },
      getMenus(user: any) {
        if (user && user.isActive) {
          return [
            {
              label: 'Inactivate User',
              action: 'inactivateUser',
              disabled: false,
            },
          ];
        } else {
          return [
            {
              label: 'Activate User',
              action: 'activateUser',
              disabled: false,
            },
          ];
        }
      },
      async activateUser(userId: number) {
        this.updateUser({ isActive: true, userId })
          .then((res) => {
            this.getUsers();
            this.setNotification({
              title: res.message,
              type: 'success',
            });
          })
          .catch((error) => {
            this.setNotification({
              title: 'Error Occured while updating User!',
              subtitle: error.message,
              type: 'error',
            });
          });
      },
      async inactivateUser(userId: number) {
        this.updateUser({ isActive: false, userId })
          .then((res) => {
            this.getUsers();
            this.setNotification({
              title: res.message,
              type: 'success',
            });
          })
          .catch((error) => {
            this.setNotification({
              title: 'Error Occured while updating User!',
              subtitle: error.message,
              type: 'error',
            });
          });
      },
      getPaginatedUsers(page: number) {
        this.getUsers({ page });
      },
    },
  });
