
  import { defineComponent } from 'vue';
  import MyReviews from './MyReviews.vue';
  import ITab from '../../interfaces/ITab';
  import { mapActions, mapState, mapMutations } from 'vuex';
  import MyBasicDetails from './MyBasicDetails.vue';
  import MyAdvanceDetails from './MyAdvanceDetails.vue';
  import MyPosts from './MyPosts.vue';
  import MyJobs from './MyJobs.vue';
  import MyEvents from './MyEvents.vue';
  import MyAdvertisement from './MyAdvertisements.vue';
  import PaymentAndSubscription from './PaymentAndSubscription.vue';
  import { PencilIcon } from '@heroicons/vue/solid';
  import Auth from '@/middleware/Auth';
  export default defineComponent({
    components: {
      MyReviews,
      MyBasicDetails,
      MyAdvanceDetails,
      MyPosts,
      MyJobs,
      MyEvents,
      MyAdvertisement,
      PencilIcon,
      PaymentAndSubscription,
    },
    data() {
      return {
        tabs: [
          { name: 'Basic Details', code: 'basic', current: true },
          { name: 'Advance Details', code: 'advance', current: false },
          { name: 'Reviews', code: 'review', current: false },
          { name: 'Posts', code: 'post', current: false },
          { name: 'Jobs', code: 'job', current: false },
          { name: 'Events', code: 'event', current: false },
          { name: 'Advertisement', code: 'advertisement', current: false },
          {
            name: 'Subscription & Payments',
            code: 'subscription',
            current: false,
          },
        ],
        selectedTab: 'basic',
        postFormModal: false,
        eventFormModal: false,
        jobFormModal: false,
        prevRoute: null,
      };
    },
    computed: {
      ...mapState('user', ['user']),
    },

    async mounted() {
      if (this.$route.query.payment_success) {
        this.setNotification({
          title: 'Payment Done Successfully!',
          type: 'success',
        });
      }
      if (this.$route.query && this.$route.query.tab) {
        this.selectedTab = this.$route.query.tab.toString();
        this.tabs = this.tabs.map((i) => {
          i.code === this.selectedTab
            ? (i.current = true)
            : (i.current = false);
          return i;
        });
      }

      const loggedInUser = Auth.getUser();
      this.setUser(loggedInUser);
      if (this.$route.query.activateSubscriptionTab == 'true') {
        this.changeTab({
          name: 'Subscription & Payments',
          code: 'subscription',
          current: false,
        });
      }
    },
    methods: {
      ...mapActions('user', [
        'getUserPosts',
        'getUserJobs',
        'getUserEvents',
        'getUser',
        'uploadProfileImage',
        'uploadCoverImage',
        'register',
      ]),
      ...mapMutations('notification', ['setNotification']),
      ...mapMutations('user', ['setUser']),

      changeTab(tab: ITab) {
        if (this.$route.query.tab) {
          this.$router.push('/edit-profile');
        }

        this.selectedTab = tab.code;
        this.tabs = this.tabs.map((i) => {
          i.name === tab.name ? (i.current = true) : (i.current = false);
          return i;
        });
        if (tab.name === 'Posts') {
          this.postTab();
        }
        if (tab.name === 'Jobs') {
          this.jobTab();
        }
        if (tab.name === 'Events') {
          this.eventTab();
        }
      },
      async postTab() {
        await this.getUserPosts({ userId: this.user.id });
      },

      async jobTab() {
        await this.getUserJobs({ userId: this.user.id });
      },

      async eventTab() {
        await this.getUserEvents({ userId: this.user.id });
      },
      async uploadProfileImageFile(event: any) {
        const file = event.target.files[0];
        this.uploadProfileImage(file)
          .then(() => {
            this.getUser();
            this.setNotification({
              title: 'Profile Image Uploaded Successfully!',
              type: 'success',
            });
          })
          .catch(() => {
            this.setNotification({
              title: 'Error Occured While Uploading Profile Image!',
              type: 'success',
            });
          });
      },

      uploadCoverImageFile(event: any) {
        const file = event.target.files[0];
        this.uploadCoverImage(file)
          .then(() => {
            this.getUser();
            this.setNotification({
              title: 'Cover Image Uploaded Successfully!',
              type: 'success',
            });
          })
          .catch(() => {
            this.setNotification({
              title: 'Error Occured While Uploading Profile Image!',
              type: 'success',
            });
          });
      },
    },
  });
