
  import { computed, defineComponent } from 'vue';
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';

  export default defineComponent({
    props: {
      visibility: {
        type: Boolean,
      },
      title: {
        type: String,
      },
    },
    components: {
      Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionChild,
      TransitionRoot,
    },
    setup(props, { emit }) {
      const open = computed({
        get: () => props.visibility,
        set: (value) => emit('close', value),
      });
      return {
        open,
      };
    },
  });
