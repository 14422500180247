
  import { defineComponent } from 'vue';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import { ref } from 'vue';
  import {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  } from '@headlessui/vue';
  import { format } from 'date-fns';

  const postTypes = [{ name: 'offer' }, { name: 'inquiry' }];
  const certificates = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];
  const postStatuses = [
    { name: 'prime' },
    { name: 'used' },
    { name: 'surplus' },
  ];
  export default defineComponent({
    components: {
      RadioGroup,
      RadioGroupLabel,
      RadioGroupOption,
    },

    data() {
      return {
        errors: {} as Record<string, unknown>,
        file: '',
        resetPostForm: {
          id: null,
          userId: '',
          location: '',
          name: '',
          email: '',
          description: '',
          material: '',
          origin: '',
          productionDate: format(new Date(), 'yyyy-MM-dd'),
          date: '',
          quantityInTon: null,
          quantityInPiece: null,
          certificate: true,
          type: 'offer',
          status: 'prime',
          thumbnailImage: '',
          subject: '',
          deletedAt: '',
        },
      };
    },
    setup() {
      const selectedPostType = ref(postTypes[0]);
      const selectedPostStatus = ref(postStatuses[0]);
      return {
        postTypes,
        certificates,
        selectedPostType,
        postStatuses,
        selectedPostStatus,
      };
    },

    computed: {
      ...mapState('user', ['user']),
      ...mapState('post', ['post']),
    },

    mounted() {
      if (this.post && this.post.id) {
        this.post.productionDate = format(
          new Date(this.post.date),
          'yyyy-MM-dd',
        );
        const setPostType = this.postTypes.filter(
          (i) => i.name === this.post.type,
        );
        this.selectedPostType = setPostType[0];
        const setPostStatus = this.postStatuses.filter(
          (i) => i.name === this.post.status,
        );
        this.selectedPostStatus = setPostStatus[0];
      }
    },

    unmounted() {
      this.resetPost(this.resetPostForm);
    },

    methods: {
      ...mapActions('post', ['addPost', 'updatePost']),
      ...mapActions('user', ['getUserPosts']),
      ...mapMutations('post', ['resetPost']),
      ...mapMutations('notification', ['setNotification']),

      uploadImage(event: any) {
        this.file = event.target.files[0];
      },
      validateForm() {
        this.errors = {};

        if (
          this.post.material === '' ||
          this.post.material === null ||
          this.post.material === undefined
        ) {
          this.errors.material = 'Material is Required!';
        }
        if (
          this.post.subject === '' ||
          this.post.subject === null ||
          this.post.subject === undefined
        ) {
          this.errors.subject = 'Subject is Required!';
        }

        const hasErrors = Boolean(Object.keys(this.errors).length);

        return hasErrors;
      },
      submit() {
        try {
          const hasErrors = this.validateForm();
          if (!hasErrors) {
            this.post.userId = this.user.id;
            this.post.type = this.selectedPostType.name;
            this.post.status = this.selectedPostStatus.name;
            if (this.file) {
              this.post.thumbnailImage = this.file;
            } else {
              delete this.post.thumbnailImage;
            }

            if (this.post.id) {
              this.updatePost(this.post)
                .then((response) => {
                  this.setNotification({
                    title: response.message,
                    type: 'success',
                  });
                  this.resetPost(this.resetPostForm);
                  this.getUserPosts({ userId: this.user.id });
                  this.$emit('cancel');
                })
                .catch((err) => {
                  this.parseErrors(err);
                });
            } else {
              this.addPost(this.post)
                .then((response) => {
                  this.setNotification({
                    title: response.message,
                    type: 'success',
                  });
                  this.resetPost(this.resetPostForm);
                  this.getUserPosts({ userId: this.user.id });
                  this.$emit('cancel');
                })
                .catch((err) => {
                  if (err.status === 422) {
                    this.setNotification({
                      title: err.data.errors.message,
                      type: 'error',
                    });
                  } else {
                    this.parseErrors(err.data.errors);
                  }
                });
            }
          }
        } catch (error) {
          this.setNotification({
            title: 'Error Occured while adding Post!',
            type: 'error',
          });
        }
      },
      parseErrors(errors: any) {
        Object.keys(errors).forEach((i: any) => {
          [this.errors[i]] = errors[i];
        });
      },
    },
  });
