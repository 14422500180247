
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';
  import { defineComponent } from '@vue/runtime-core';

  export default defineComponent({
    props: {
      meta: {
        type: Object,
      },
    },
    data: () => {
      return {};
    },
    components: {
      ChevronLeftIcon,
      ChevronRightIcon,
    },
    methods: {
      paginate(n: number) {
        this.$emit('paginate', n);
      },
      next() {
        if (this.meta) {
          this.$emit('paginate', this.meta.currentPage + 1);
        }
      },
      previous() {
        if (this.meta) {
          this.$emit('paginate', this.meta.currentPage - 1);
        }
      },
    },
  });
