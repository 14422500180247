import actions from './actions';
import mutations from './mutations';
import IAdvertisement from '@/interfaces/IAdvertisement';
import IAdvertisementType from '@/interfaces/IAdvertisementType';
import { format } from 'date-fns';

const state = {
  advertisements: [],
  bannerAdvertisements: [],
  userAdvertisements: [],
  advertisement: {
    meta: {
      title: '',
      description: '',
      label: '',
      link: '',
    },
    advertisementTypeCode: 'banner',
    startDate: format(new Date(), 'yyyy-MM-dd'),
    postId: null,
  },
  advertisementTypes: [],
  postAdvertisements: [],
};

const Advertisement = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default Advertisement;

export interface State {
  userAdvertisements: Array<IAdvertisement>;
  advertisement: IAdvertisement;
  advertisements: Array<IAdvertisement>;
  advertisementTypes: Array<IAdvertisementType>;
  bannerAdvertisements: Array<IAdvertisement>;
  postAdvertisements: Array<any>;
}
