import IEvent from '@/interfaces/IEvent';
import IJob from '@/interfaces/IJob';
import IPost from '@/interfaces/IPost';
import IUser from '@/interfaces/IUser';
import actions from './actions';
import mutations from './mutations';

const state = {
  users: [],
  posts: [],
  jobs: [],
  events: [],
  postMeta: {},
  jobMeta: {},
  eventMeta: {},
  userMeta: {},
  counts: {},
};
const Admin = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default Admin;
export interface State {
  users: Array<IUser>;
  posts: Array<IPost>;
  jobs: Array<IJob>;
  events: Array<IEvent>;
  postMeta: any;
  jobMeta: any;
  eventMeta: any;
  userMeta: any;
  counts: any;
}
