import axios from '../../../middleware/Axios';
import { ActionContext } from 'vuex';
import { State } from './index';
import { RootState } from '@/store';
import camelToSnake from '../../../utils/camelToSnake';
import IUserAdvertisement from '@/interfaces/IUserAdvertisement';

const actions = {
  addAdvertisement(
    context: ActionContext<State, RootState>,
    payload: Record<string, string>,
  ): Promise<any> {
    const formData = new FormData();
    const requestBody = camelToSnake(payload);
    for (const key in requestBody) {
      if (typeof requestBody[key] === 'boolean') {
        formData.append(key, JSON.stringify(requestBody[key]));
      }
      if (requestBody[key]) {
        if (key === 'meta') {
          formData.append(key, JSON.stringify(requestBody[key]));
        } else {
          formData.append(key, requestBody[key]);
        }
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/advertisements', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  getAdvertisement(
    context: ActionContext<State, RootState>,
    payload: IUserAdvertisement,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/advertisements/${payload.advertisementId}`)
        .then((response) => {
          context.commit('setAdvertisement', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  getBannerAdvertisements(
    context: ActionContext<State, RootState>,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/banner-ads`)
        .then((response) => {
          context.commit('setBannerAdvertisements', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  getPostAdvertisements(
    context: ActionContext<State, RootState>,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/post-ads`)
        .then((response) => {
          context.commit('setPostAdvertisements', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  updateAdvertisement(
    context: ActionContext<State, RootState>,
    payload: Record<string, string>,
  ): Promise<any> {
    const formData = new FormData();
    const requestBody = camelToSnake(payload);
    for (const key in requestBody) {
      if (requestBody[key]) {
        if (key === 'meta') {
          formData.append(key, JSON.stringify(requestBody[key]));
        } else if (typeof requestBody[key] === 'boolean') {
          formData.append(key, JSON.stringify(requestBody[key]));
        } else {
          formData.append(key, requestBody[key]);
        }
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`api/v1/advertisements/${payload.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /* --------------- ------------ Advertisement Types Apis ----------------------*/

  getAdvertisementTypes(
    context: ActionContext<State, RootState>,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/advertisement-types`)
        .then((response) => {
          context.commit('setAdvertisementTypes', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};

export default actions;
