
  import { CheckIcon } from '@heroicons/vue/outline';
  import { defineComponent } from '@vue/runtime-core';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';

  const membershipPricing = [
    {
      title: 'Free',
      price: 0,
      frequency: '/year',
      description: 'The essentials to provide your best work for clients.',
      features: [
        '2 posts per month',
        '2 events per month',
        '1 job per month',
        'Needs Admin Approval for onboarding  ',
      ],
      mostPopular: false,
      type: 'membership',
      buttonName: '',
    },
    {
      title: 'Professional',
      price: 60,
      priceId: process.env.VUE_APP_PROFESSIONAL_PRICE,
      frequency: '/year',
      description: 'A plan that scales with your rapidly growing business.',
      features: [
        '10 posts per month',
        '10 events per month',
        '10 job per month',
        'One post advertisement per annum',
        'One banner advertisement per annum',
        'Email Support',
        'No Admin Approval for onboarding',
      ],
      mostPopular: true,
      type: 'membership',
      buttonName: '',
    },
    {
      title: 'Enterprise',
      price: 180,
      priceId: process.env.VUE_APP_ENTERPRISE_PRICE,
      frequency: '/year',
      description: 'Dedicated support and infrastructure for your company.',
      features: [
        'Unlimited posts',
        'Unlimited events',
        'Unlimited jobs',
        'Twelve post advertisements per annum',
        'Twelve banner advertisements per annum',
        '8 hours Email Support',
        'No Admin Approval for onboarding',
      ],
      mostPopular: false,
      type: 'membership',
      buttonName: '',
    },
  ];
  const advertisementPricing = [
    {
      title: 'Classified Post',
      code: 'post',
      price: 20,
      priceId: process.env.VUE_APP_CLASSIFIED_POST_PRICE,
      frequency: '',
      description: 'Highlight your post on home page for 7 days slot',
      features: [
        'Promote existing post',
        'Promote new post',
        'Multiple posts per month',
        'Change post',
      ],
      cta: 'Annual billing',
      mostPopular: false,
      type: 'advertising',
    },
    {
      title: 'Banner',
      code: 'banner',
      priceId: process.env.VUE_APP_BANNER_PRICE,
      price: 50,
      frequency: '',
      description: 'Promote your business on home page banner for 7 days slot',
      features: [
        'Customized title',
        'Customized image',
        'Customized call to action',
        'Promote your brand',
      ],
      cta: 'Annual billing',
      mostPopular: false,
      type: 'advertising',
    },
  ];

  export default defineComponent({
    components: {
      CheckIcon,
    },
    setup() {
      return {
        membershipPricing,
        advertisementPricing,
      };
    },
    data: () => {
      return {
        showPaymentWindow: false,
        selectedType: 'membership',
        selectedPlanId: null,
        proRataPrice: null,
        stripe: {} as Stripe | null,
        elements: {} as StripeElements,
        isFreePlan: false,
        priceId: 'price_1JpsXOFWESKmRsAAcMmgAxJr',
        memberships: [] as Array<any>,
      };
    },
    computed: {
      ...mapState('user', ['user']),
      ...mapState('subscriptionPlans', ['plans']),
    },
    mounted() {
      this.getUser();
      this.getSubscriptionPlans();
      this.memberships = this.membershipPricing.map((item) => {
        return {
          ...item,
          buttonName: this.getButtonName(item, this.user.subscription),
        };
      });
    },
    methods: {
      ...mapActions('user', [
        'getUser',
        'getPaymentIntent',
        'getProRataPrice',
        'updateUser',
        'createAdvertisementPaymentIntent',
      ]),
      ...mapActions('subscriptionPlans', ['getSubscriptionPlans']),

      ...mapMutations('notification', ['setNotification']),

      showMemberships() {
        this.selectedType = 'membership';
      },
      showAdvertising() {
        this.selectedType = 'advertising';
      },
      changePricingPlan(tier: any) {
        const [selectedPlan] = this.plans.filter((i: any) => {
          return i.name.toLowerCase() === tier.title.toLowerCase();
        });
        // if current plan is same as selected plan, do nothing
        if (selectedPlan.name === this.user.subscription.name) {
          return;
        }
        this.selectedPlanId = selectedPlan.id;
        this.priceId = tier.priceId;

        if (
          this.user.subscription.name.toLowerCase() === 'professional' &&
          selectedPlan.name === 'Enterprise' &&
          this.proRataPrice === null
        ) {
          this.getProRataPrice(this.priceId).then((res) => {
            this.proRataPrice = res.amountDue;
          });
          return;
        }

        this.initializePayment();
        this.showPaymentWindow = true;
      },

      async initializePayment() {
        const requestBody = {
          id: this.user.id,
          name: this.user.name,
          email: this.user.email,
          isFreePlan: false,
          priceId: this.priceId,
        };
        const response = await this.getPaymentIntent(requestBody);

        const formElement = window.document.querySelector('#payment-form');
        if (formElement) {
          formElement.addEventListener('submit', this.handleSubmit);
        }

        this.stripe = await loadStripe(
          process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
        );
        if (this.stripe) {
          this.elements = this.stripe.elements({
            clientSecret: response.clientSecret,
          });

          const paymentElement = this.elements.create('payment');
          paymentElement.mount('#payment-element');
        }
      },

      async handleBuyAdvertisement({ priceId, code }: any) {
        try {
          const { data } = await this.createAdvertisementPaymentIntent({
            priceId,
            code,
          });
          const stripe = await loadStripe(
            process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
          );
          if (stripe) {
            window.open(data?.sessionId.url);
          }
        } catch (error) {
          console.error(error);
        }
      },

      async handleSubmit(e: Event): Promise<void> {
        e.preventDefault();
        this.setLoading(true);
        if (this.stripe) {
          const { error } = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
              return_url: `${process.env.VUE_APP_FRONTEND_BASE_URL}/payment-callback`,
            },
          });

          if (
            error.type === 'card_error' ||
            error.type === 'validation_error'
          ) {
            this.setNotification({
              title: 'Error',
              subtitle: error.message,
              type: 'error',
            });
          }
          this.setLoading(false);
        }
      },
      showMessage(messageText: string | undefined) {
        const messageContainer = document.querySelector(
          '#payment-message',
        ) as HTMLDivElement;

        messageContainer?.classList.remove('hidden');
        messageContainer.textContent = messageText || null;

        setTimeout(function () {
          messageContainer?.classList.add('hidden');
          messageContainer.textContent = '' || null;
        }, 4000);
      },
      setLoading(isLoading: boolean) {
        const submitButton = document.querySelector(
          '#submit',
        ) as HTMLButtonElement;
        const spinner = document.querySelector('#spinner');
        const buttonText = document.querySelector('#button-text');
        if (isLoading) {
          submitButton.disabled = true;
          spinner?.classList.remove('hidden');
          buttonText?.classList.add('hidden');
        } else {
          submitButton.disabled = false;
          spinner?.classList.add('hidden');
          buttonText?.classList.remove('hidden');
        }
      },
      back() {
        this.showPaymentWindow = false;
      },
      getButtonName(tier: any, currentSubscription: any): string {
        if (currentSubscription.name.toLowerCase() === 'free'.toLowerCase()) {
          if (tier.title.toLowerCase() === 'free') {
            return 'Current Plan';
          } else {
            return 'Upgrade Plan';
          }
        } else if (
          currentSubscription.name.toLowerCase() ===
          'professional'.toLowerCase()
        ) {
          if (tier.title.toLowerCase() === 'free') {
            return '';
          } else if (tier.title.toLowerCase() === 'professional') {
            return 'Current Plan';
          } else if (this.proRataPrice) {
            return `Pay ${this.proRataPrice} to upgrade`;
          } else {
            return 'Check ProRata Price';
          }
        } else if (
          currentSubscription.name.toLowerCase() === 'enterprise'.toLowerCase()
        ) {
          if (tier.title.toLowerCase() === 'enterprise') {
            return 'Current Plan';
          }
          return '';
        } else {
          return '';
        }
      },
    },
  });
