
  import Auth from '@/middleware/Auth';
  import { defineComponent } from '@vue/runtime-core';
  import { mapMutations, mapState } from 'vuex';
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
  export default defineComponent({
    components: {
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
    },
    data() {
      return {
        showRegistration: false,
        userNavigation: [] as Array<Record<string, string>>,
        showMenus: false,
        menus: [
          {
            name: 'Home',
            link: '/',
          },
          {
            name: 'About Us',
            link: '/about',
          },
          {
            name: 'Pricing',
            link: '/pricing',
          },
          {
            name: 'Members',
            link: '/members',
          },
          {
            name: 'Posts',
            link: '/posts',
          },
          {
            name: 'Jobs',
            link: '/jobs',
          },
          {
            name: 'Events',
            link: '/events',
          },
          {
            name: 'Your Profile',
            link: '/edit-profile',
          },
          {
            name: 'Change Password',
            link: '/change-password',
          },
        ],
      };
    },
    computed: {
      ...mapState('user', ['user', 'loggedInUser']),
    },
    watch: {
      user: {
        handler: function (val) {
          if (val && val.isAdmin) {
            this.userNavigation = [
              { name: 'Your Profile', code: 'profile' },
              { name: 'Admin', code: 'admin' },
              { name: 'Sign Out', code: 'signOut' },
            ];
          } else {
            this.userNavigation = [
              { name: 'Your Profile', code: 'profile' },
              { name: 'Change Password', code: 'changePassword' },
              { name: 'Sign Out', code: 'signOut' },
            ];
          }
        },
        deep: true,
      },
    },
    methods: {
      ...mapMutations('notification', ['setNotification']),
      ...mapMutations('user', ['setUser', 'setLoggedInUser']),
      toToLogin() {
        this.$router.push('/login');
      },
      logout() {
        const auth = new Auth();
        auth.logout();
        this.setUser({});
        this.setLoggedInUser({});
        this.$router.push('/login');
      },
      goto(command: string) {
        if (command === 'profile') {
          this.$router.push('/edit-profile');
        } else if (command === 'signOut') {
          const auth = new Auth();
          auth.logout();
          this.setUser({});
          this.setLoggedInUser({});
          this.$router.push('/login');
        } else if (command === 'changePassword') {
          this.$router.push('/change-password');
        } else {
          this.$router.push('/admin-dashboard');
        }
      },
    },
  });
