
  import { defineComponent } from 'vue';
  import { mapActions, mapState } from 'vuex';
  import { toCapitalize } from '../../utils/camelToReadable';
  import { camelCase } from 'lodash';
  import Dropdown from '../../components/Dropdown.vue';
  import Modal from '../../components/Modal.vue';
  import JobForm from '../job/JobForm.vue';
  import { SearchIcon } from '@heroicons/vue/solid';
  import { Constants } from '../../constants/constants';
  import { format } from 'date-fns';

  export default defineComponent({
    components: {
      Dropdown,
      Modal,
      JobForm,
      SearchIcon,
    },
    data() {
      return {
        abc: '',
        profile: {},
        editableProperty: '',
        readView: false,
        jobFormModal: false,
        tableActions: [
          {
            label: 'Edit',
            action: 'edit',
            disabled: false,
          },
        ],
        searchKeyword: '',
        messageForUserIfNoData: Constants.messageForUserIfNoData,
      };
    },
    watch: {
      searchKeyword: {
        handler: function (value) {
          if (value.length > 2) {
            this.getUserJobs({ userId: this.user.id, search: value });
          }
          if (value.length === 0) {
            this.getUserJobs({ userId: this.user.id });
          }
        },
      },
    },
    computed: {
      ...mapState('user', ['user', 'userJobs']),
    },

    mounted() {
      if (this.$route.name === 'View Profile') {
        this.readView = true;
      }
    },
    methods: {
      ...mapActions('user', ['getUserJobs']),
      ...mapActions('job', ['getJob']),

      getInitials(val: string) {
        return val
          .split(' ')
          .map((i) => i.charAt(0))
          .join('')
          .toUpperCase();
      },
      labelize(field: string) {
        if (field) {
          return toCapitalize(field);
        }
      },
      editField(field: string) {
        if (field) {
          this.editableProperty = camelCase(field);
        }
      },

      getFirstLetterCapital(value: string): string {
        if (value) {
          return toCapitalize(value);
        }
        return '';
      },
      formatDate(date: Date) {
        return format(new Date(date), 'yyyy-MM-dd');
      },
      async addJob(jobId: string) {
        if (jobId) {
          await this.getJob(jobId);
        }
        this.jobFormModal = true;
      },
      closeJobModal() {
        this.jobFormModal = false;
        this.getUserJobs({ userId: this.user.id });
      },
    },
  });
