import IReview from '@/interfaces/IReview';
import IUserReview from '@/interfaces/IUserReview';
import { RootState } from '@/store';
import camelToSnake from '@/utils/camelToSnake';
import { ActionContext } from 'vuex';
import axios from '../../../middleware/Axios';
import { State } from './index';

const actions = {
  // get user reviews
  getUserReviews(
    context: ActionContext<State, RootState>,
    payload: number,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/reviews/users/${payload}`)
        .then((response) => {
          context.commit('setUserReviews', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // add review
  addReview(
    context: ActionContext<State, RootState>,
    payload: IReview,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/review', camelToSnake(payload))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit review
  getReview(
    context: ActionContext<State, RootState>,
    payload: IUserReview,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/reviews/${payload.reviewId}`)
        .then((response) => {
          context.commit('setReview', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  //   },

  // update review
  updateReview(
    context: ActionContext<State, RootState>,
    payload: IReview,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(`api/v1/review/${payload.id}`, camelToSnake(payload))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getReviewsForSelectedUser(
    context: ActionContext<State, RootState>,
    payload: number,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/reviews/for/user/${payload}`)
        .then((response) => {
          context.commit('setUserReviews', response.data.data);

          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getReviewsFromSelectedUser(
    context: ActionContext<State, RootState>,
    payload: number,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/reviews/from/user/${payload}`)
        .then((response) => {
          context.commit('setUserReviews', response.data.data);

          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default actions;
