
  import { defineComponent } from '@vue/runtime-core';
  import { mapState } from 'vuex';
  import { capitalize } from '../../utils/camelToReadable';

  export default defineComponent({
    components: {},
    computed: {
      ...mapState('job', ['job']),
    },
    methods: {
      getFirstLetterCapital(value: string): string {
        return capitalize(value);
      },
      formatDate(date: Date) {
        return new Date(date).toISOString().split('T')[0];
      },
    },
  });
