
import { defineComponent } from 'vue';
import MyReviews from './MyReviews.vue';
import MyBasicDetails from './MyBasicDetails.vue';
import MyAdvanceDetails from './MyAdvanceDetails.vue';
import ITab from '../../interfaces/ITab';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  components: {
    MyBasicDetails,
    MyReviews,
    MyAdvanceDetails,
  },
  data() {
    return {
      tabs: [
        { name: 'Basic Details', code: 'basic', current: true },
        { name: 'Advance Details', code: 'advance', current: false },
        { name: 'Reviews', code: 'review', current: false },
      ],
      selectedTab: 'basic',
    };
  },
  computed: {
    ...mapState('user', ['user']),
  },
  mounted() {
    this.getUserById(this.$route.params.userId);
  },

  methods: {
    ...mapActions('user', ['getUserById']),
    changeTab(tab: ITab) {
      this.selectedTab = tab.code;
      this.tabs = this.tabs.map((i) => {
        i.name === tab.name ? (i.current = true) : (i.current = false);
        return i;
      });
    },
  },
});
