import IRole from '@/interfaces/IRole';
import IRoleCategory from '@/interfaces/IRoleCategory';
import { State } from './index';

const mutations = {
  setRoles(state: State, roles: Array<IRole>): void {
    state.roles = roles;
  },
  setRoleCategories(state: State, roleCategories: Array<IRoleCategory>): void {
    state.roleCategories = roleCategories;
  },
  setCategories(state: State, categories: Array<IRoleCategory>): void {
    state.categories = categories;
  },
};
export default mutations;
