import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" }
const _hoisted_2 = { class: "flex-1 flex justify-between sm:hidden" }
const _hoisted_3 = { class: "hidden sm:flex-1 sm:flex sm:items-center sm:justify-between" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text-sm text-gray-700" }
const _hoisted_6 = { class: "font-medium" }
const _hoisted_7 = { class: "font-medium" }
const _hoisted_8 = { class: "font-medium" }
const _hoisted_9 = { class: "flex-1 flex justify-between sm:justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.meta.currentPage > 1)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.previous())),
            class: "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          }, " Previous "))
        : _createCommentVNode("", true),
      (_ctx.meta.currentPage != _ctx.meta.lastPage)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.next())),
            class: "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          }, " Next "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.meta)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, [
              _createTextVNode(" Showing " + _toDisplayString(' ') + " "),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.meta.from), 1),
              _createTextVNode(" " + _toDisplayString(' ') + " to " + _toDisplayString(' ') + " "),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.meta.to), 1),
              _createTextVNode(" " + _toDisplayString(' ') + " of " + _toDisplayString(' ') + " "),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.meta.total), 1),
              _createTextVNode(" " + _toDisplayString(' ') + " results ")
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.meta.currentPage > 1)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.previous())),
              class: "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            }, [
              _createVNode(_component_ChevronLeftIcon, {
                class: "h-5 w-5",
                "aria-hidden": "true"
              }),
              _createTextVNode(" Previous ")
            ]))
          : _createCommentVNode("", true),
        (_ctx.meta.currentPage != _ctx.meta.lastPage)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.next())),
              class: "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            }, [
              _createTextVNode(" Next "),
              _createVNode(_component_ChevronRightIcon, {
                class: "h-5 w-5",
                "aria-hidden": "true"
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}