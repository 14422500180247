
  import { defineComponent } from '@vue/runtime-core';
  import { mapActions, mapState, mapMutations } from 'vuex';
  import Modal from '../components/Modal.vue';
  import TermsAndConditions from './TermsAndConditions.vue';
  import IRoleCategory from '@/interfaces/IRoleCategory';
  import IRole from '@/interfaces/IRole';
  import { Stripe, StripeElements } from '@stripe/stripe-js';
  import { EyeOffIcon, EyeIcon } from '@heroicons/vue/solid';
  export default defineComponent({
    components: {
      Modal,
      TermsAndConditions,
      EyeOffIcon,
      EyeIcon,
    },

    data: () => {
      return {
        token: null,
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
        errors: {} as Record<string, unknown>,
        confirmPassword: '',
        wrongConfirmPassword: false as boolean,
        isTermsAccepted: null,
        roleCategoryClickedOutside: true,
        role: {} as IRole,
        roleCategoryId: null,
        stripe: {} as Stripe | null,
        step: 1,
        showTerms: false,
        loader: false,

        registrationForm: {
          name: '',
          email: '',
          password: '',
          countryId: 356,
          roleCategories: [] as Array<number>,
          companyName: '',
          website: '',
          isFreePlan: true,
          isActive: true,
          subscriptionPlanId: 0,
        },
        showTermsErrorMessage: false,
        elements: {} as StripeElements,
        showPassword: false,
      };
    },
    computed: {
      ...mapState('role', ['roles', 'roleCategories']),
      ...mapState('subscriptionPlans', ['plans']),
      ...mapState('user', ['countries']),
      subTitle() {
        switch (this.step) {
          case 1:
            return 'Basic Details';
          case 2:
            return 'Advance Details';
          case 3:
            return 'Make Payment';
          default:
            return 'Basic Details';
        }
      },
    },

    beforeMount() {
      this.getCountries();
      this.getRoles();
      this.getSubscriptionPlans();
    },
    methods: {
      ...mapActions('role', ['getRoles', 'getRoleCategoriesByRoleId']),
      ...mapActions('user', [
        'register',
        'login',
        'getPaymentIntent',
        'getCountries',
      ]),
      ...mapMutations('notification', ['setNotification']),
      ...mapActions('subscriptionPlans', ['getSubscriptionPlans']),

      showTermsAndConditions() {
        this.showTerms = !this.showTerms;
      },

      validateConfirmPassword() {
        if (this.registrationForm.password != this.confirmPassword) {
          this.wrongConfirmPassword = true;
          this.errors.confirmPassword = 'Password Mismatched!';
        } else {
          this.wrongConfirmPassword = false;
          this.errors.confirmPassword = null;
        }
      },
      next() {
        const hasErrors = this.validateForm();
        if (!hasErrors) {
          if (this.role.code === 'student') {
            const roleCategory = this.roleCategories.find(
              (i: IRoleCategory) => i.roleId === this.role.id,
            );
            this.registrationForm.roleCategories = [roleCategory.id];
          }
          this.step = this.step + 1;
        }
      },
      back() {
        this.step = 1;
      },

      collapseRoleCategories() {
        this.roleCategoryClickedOutside = true;
      },
      save() {
        const hasErrors = this.validateForm();
        if (!hasErrors) {
          this.loader = true;
          const [subscriptionPlan] = this.plans.filter((i: any) => {
            return i.name.toLowerCase() === 'free';
          });
          this.registrationForm.subscriptionPlanId = subscriptionPlan.id;
          this.register(this.registrationForm)
            .then((res) => {
              this.loader = false;
              this.setNotification({
                title: 'Registration Successful!',
                subtitle: res.message,
                type: 'success',
              });

              this.$router.push({
                path: '/login',
                query: { isRegistration: 'true' },
              });
            })
            .catch((err) => {
              this.loader = false;
              const { errors } = err;
              const [errorMessage]: any = Object.values(errors)[0];
              this.setNotification({
                title: 'Error Occured!',
                subtitle:
                  errorMessage &&
                  typeof errorMessage === 'string' &&
                  errorMessage.length > 0
                    ? errorMessage
                    : err.message,
                type: 'error',
              });
            });
        }
      },
      validateForm() {
        if (this.step === 1) {
          this.errors = {};
          if (this.registrationForm.name === '') {
            this.errors.name = 'Name is Required!';
          }
          if (this.registrationForm.email === '') {
            this.errors.email = 'Email is Required!';
          }
          if (this.registrationForm.password === '') {
            this.errors.password = 'Password is Required!';
          }
          if (
            this.confirmPassword === '' ||
            this.wrongConfirmPassword === true
          ) {
            this.errors.confirmPassword = 'Password Mismatched!';
          }
          if (!Object.keys(this.role).length) {
            this.errors.role = 'Role is Required!';
          }
          if (this.registrationForm.countryId === null) {
            this.errors.country = 'Country is Required!';
          }
          if (this.isTermsAccepted === null || this.isTermsAccepted === false) {
            this.errors.terms = 'Please Accept Terms And Conditions!';
          }
        }
        if (this.step === 2) {
          this.errors = {};
          if (this.role.code != 'student') {
            if (this.registrationForm.roleCategories.length === 0) {
              this.errors.category = 'Select Atleast One Category!';
            }
            if (this.registrationForm.companyName === '') {
              this.errors.companyName = 'Company Name is Required!';
            }
          }
        }
        const hasErrors = Boolean(Object.keys(this.errors).length);
        return hasErrors;
      },
    },
  });
