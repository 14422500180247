
  import { defineComponent } from '@vue/runtime-core';
  import { mapActions, mapState } from 'vuex';
  import 'vue3-carousel/dist/carousel.css';
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

  export default defineComponent({
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
    },
    data: () => {
      return {
        settings: {
          transition: '1800',
          itemsToShow: 5,
          wrapAround: true,
          autoplay: '6000',
        },
        breakpoints: {
          0: {
            itemsToShow: 1,
          },
          350: {
            itemsToShow: 1.5,
          },
          470: {
            itemsToShow: 2,
          },
          600: {
            itemsToShow: 2.5,
          },
          700: {
            itemsToShow: 3,
          },
          825: {
            itemsToShow: 3.5,
          },
          950: {
            itemsToShow: 4,
          },
          1212: {
            itemsToShow: 5,
          },
          1500: {
            itemsToShow: 6,
          },
          1674: {
            itemsToShow: 7,
          },
          1880: {
            itemsToShow: 8,
          },
        },
      };
    },

    computed: {
      ...mapState('post', ['posts']),
      ...mapState('role', ['categories']),
      ...mapState('advertisement', [
        'bannerAdvertisements',
        'postAdvertisements',
      ]),
    },
    mounted() {
      this.getPosts({ perPage: 20 });
      this.getBannerAdvertisements();
      this.getPostAdvertisements();
      this.getAllRoleCategories();
    },
    methods: {
      ...mapActions('post', ['getPosts']),
      ...mapActions('role', ['getAllRoleCategories']),
      ...mapActions('advertisement', [
        'getBannerAdvertisements',
        'getPostAdvertisements',
      ]),
      formatDate(date: Date) {
        return new Date(date).toISOString().split('T')[0];
      },
      getInitials(val: string) {
        return val
          .split(' ')
          .map((i) => i.charAt(0))
          .join('')
          .toUpperCase();
      },
      openAdvertisement(post: any) {
        if (post.meta && post.meta.link) {
          window.open(post.meta.link, '_blank');
        }
      },
      //filter members list with category
      goToMembers(category: any) {
        this.$router.push(`/members?category=${category.id}`);
      },
      showPostDetails(postId: number) {
        this.$router.push(`/posts?id=${postId}`);
      },
      goToPosts() {
        this.$router.push(`/posts`);
      },
    },
  });
