import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import PostList from '../views/PostList.vue';
import JobList from '../views/JobList.vue';
import EventList from '../views/EventList.vue';
import Pricing from '../views/Pricing.vue';
import Members from '../views/Members.vue';
import Registration from '../views/Registration.vue';
import Login from '../views/Login.vue';
import EditProfile from '../views/profile/EditProfile.vue';
import ViewProfile from '../views/profile/ViewProfile.vue';
import adminRoutes from './adminRoutes';
import AdminView from '../views/admin/AdminView.vue';
import ChangePassword from '../views/ChangePassword.vue';
import ResetPassowrd from '../views/ResetPassword.vue';
import AccessDenied from '../views/AccessDenied.vue';
import PaymentCallback from '../views/profile/PaymentCallback.vue';
function checkIfAdmin() {
  const userObj = window.localStorage.getItem('user') || '';
  let user;
  if (userObj) {
    user = JSON.parse(userObj);
    if (user.isAdmin === true) {
      return true;
    }
  }
  return { path: '/403' };
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/members',
    name: 'Members',
    component: Members,
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
  },
  {
    path: '/posts',
    name: 'Posts',
    component: PostList,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: JobList,
  },
  {
    path: '/events',
    name: 'Events',
    component: EventList,
  },

  {
    path: '/edit-profile',
    name: 'Edit Profile',
    component: EditProfile,
  },
  {
    path: '/view-profile/:userId',
    name: 'View Profile',
    component: ViewProfile,
  },
  {
    path: '/change-password',
    name: 'Change Password',
    component: ChangePassword,
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassowrd,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView,
    meta: { isAdmin: true },
    children: adminRoutes,
    beforeEnter: [checkIfAdmin],
  },
  {
    path: '/payment-callback',
    name: 'Payment Callback',
    component: PaymentCallback
  },
  {
    path: '/403',
    name: 'Access Denied',
    component: AccessDenied,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
