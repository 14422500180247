
  import { mapActions, mapState, mapMutations } from 'vuex';
  import { defineComponent } from 'vue';
  import MenuOption from '@/interfaces/MenuOption';
  import Dropdown from '../../components/Dropdown.vue';
  import { capitalize } from '../../utils/camelToReadable';
  import { Constants } from '../../constants/constants';
  import { ExclamationIcon } from '@heroicons/vue/outline';
  import { DialogTitle } from '@headlessui/vue';
  import Modal from '../../components/Modal.vue';
  import Pagination from '../../components/Pagination.vue';

  export default defineComponent({
    components: {
      Dropdown,
      DialogTitle,
      ExclamationIcon,
      Modal,
      Pagination,
    },
    data() {
      return {
        isVisible: false,
        messageForUserIfNoData: Constants.messageForUserIfNoData,
        tableActions: [
          {
            label: 'Delete',
            action: 'delete',
            disabled: false,
          },
        ] as Array<MenuOption>,
        jobToBeDeleted: '',
        showConfirmation: false,
      };
    },
    computed: {
      ...mapState('admin', ['jobs', 'jobMeta']),
    },
    mounted() {
      this.getJobs();
    },
    methods: {
      ...mapActions('admin', ['getJobs', 'deleteJob', 'restoreJob']),
      ...mapMutations('notification', ['setNotification']),

      getFirstLetterCapital(value: string): string {
        return capitalize(value);
      },
      formatDate(date: Date) {
        return new Date(date).toISOString().split('T')[0];
      },
      getMenus(job: any) {
        if (job && job.deletedAt === null) {
          return [
            {
              label: 'Delete',
              action: 'delete',
              disabled: false,
            },
          ];
        } else {
          return [
            {
              label: 'Restore',
              action: 'restore',
              disabled: false,
            },
          ];
        }
      },
      confirmDelete(jobId: string) {
        this.jobToBeDeleted = jobId;
        this.showConfirmation = true;
      },
      deleteSelectedJob() {
        this.deleteJob(this.jobToBeDeleted).then((response) => {
          this.getJobs();
          this.showConfirmation = false;
          this.setNotification({
            title: response.message,
            type: 'success',
          });
        });
      },
      restoreRecord(id: string) {
        this.restoreJob(id).then((res) => {
          this.getJobs();
          this.setNotification({
            title: res.message,
            type: 'success',
          });
        });
      },
      getPaginatedJobs(page: number) {
        this.getJobs({ page });
      },
    },
  });
