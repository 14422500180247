
  import { defineComponent } from 'vue';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  } from '@headlessui/vue';
  import { CalendarIcon, InformationCircleIcon } from '@heroicons/vue/solid';
  import { format } from 'date-fns/esm';
  export default defineComponent({
    components: {
      RadioGroup,
      RadioGroupLabel,
      RadioGroupOption,
      CalendarIcon,
      InformationCircleIcon,
    },
    data() {
      return {
        errors: {} as Record<string, unknown>,
        resetForm: {
          id: null,
          meta: {
            title: '',
            description: '',
          },
          startDate: format(new Date(), 'yyyy-MM-dd'),
          type: '',
        },
        selectedAdvertisementType: {
          code: 'banner',
        },
        file: { name: '' } as File | '',
        endDate: '',
        duration: [
          {
            key: 'One Week',
            value: 7,
          },
          {
            key: 'Two Weeks',
            value: 14,
          },
          {
            key: 'Four Weeks',
            value: 28,
          },
        ],
        showDescription: false,
        showActionDescription: false,
        showLinkDescription: false,
        showImageDescription: false,
      };
    },
    computed: {
      ...mapState('user', ['user', 'userPosts']),
      ...mapState('advertisement', ['advertisement', 'advertisementTypes']),
    },
    created() {
      if (this.advertisement.id) {
        this.endDate = this.advertisement.days;
        this.advertisement.startDate = format(
          new Date(this.advertisement.startDate),
          'yyyy-MM-dd',
        );
      }
    },
    beforeUnmount() {
      this.resetAdvertisement(this.resetForm);
    },
    mounted() {
      this.getAdvertisementTypes().then(() => {
        if (this.advertisement && this.advertisement.id) {
          const type = this.advertisementTypes.filter(
            (i: { name: any }) => i.name === this.advertisement.type.name,
          );
          this.selectedAdvertisementType = type[0];
        } else {
          this.selectedAdvertisementType = this.advertisementTypes[0];
        }
      });
      this.getUserPosts({ userId: this.user.id, image: 'required' });
    },

    methods: {
      ...mapActions('advertisement', [
        'addAdvertisement',
        'updateAdvertisement',
        'getAdvertisementTypes',
      ]),
      ...mapActions('user', ['getUserAdvertisements', 'getUserPosts']),
      ...mapMutations('advertisement', [
        'setAdvertisement',
        'resetAdvertisement',
      ]),
      ...mapMutations('notification', ['setNotification']),
      uploadBannerImage(event: any) {
        this.file = event.target.files[0];
      },
      validateForm() {
        this.errors = {};
        if (
          this.advertisement.meta.title === '' ||
          this.advertisement.meta.title === null ||
          this.advertisement.meta.title === undefined
        ) {
          this.errors.title = 'Title is required!';
        }
        if (this.user.isAdmin === false) {
          if (
            this.advertisement.meta.description === '' ||
            this.advertisement.meta.description === null ||
            this.advertisement.meta.description === undefined
          ) {
            this.errors.description = 'Description is required!';
          }
        }
        if ((this.file !== '' && this.file.name === '') || this.file === '') {
          this.errors.file = 'Image required!';
        }
        if (this.selectedAdvertisementType.code === '') {
          this.errors.type = 'Type is required!';
        }
        if (
          this.advertisement.startDate === '' ||
          this.advertisement.startDate === null ||
          this.advertisement.startDate === undefined
        ) {
          this.errors.startDate = 'Start date is required!';
        }

        if (
          this.endDate === '' ||
          this.endDate === null ||
          this.endDate === undefined
        ) {
          this.errors.endDate = 'End date is required!';
        }

        if (
          this.advertisement.meta.link === '' ||
          this.advertisement.meta.link === null
        ) {
          this.errors.link = 'Link is required!';
        }

        const hasErrors = Boolean(Object.keys(this.errors).length);

        return hasErrors;
      },
      formatDate(date: number) {
        var d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
      },
      submit() {
        try {
          const hasErrors = this.validateForm();
          if (!hasErrors) {
            this.advertisement.userId = this.user.id;
            const date = new Date(this.advertisement.startDate);
            const endDate = this.formatDate(
              date.setDate(date.getDate() + parseInt(this.endDate)),
            );

            let requestBody = {
              userId: this.user.id,
              isAdmin: this.user.isAdmin ? true : false,
              advertisementTypeCode: this.selectedAdvertisementType.code,
              //to avoid file object if its not banner
              file: this.file,
              startDate: this.advertisement.startDate,
              endDate: endDate,
              meta: this.advertisement.meta,
              ...(this.advertisement.id && { id: this.advertisement.id }),
            };

            if (this.advertisement.id) {
              this.updateAdvertisement(requestBody)
                .then((response) => {
                  this.setNotification({
                    title: response.message,
                    type: 'success',
                  });
                  this.getUserAdvertisements({ userId: this.user.id });
                  this.resetAdvertisement(this.resetForm);
                  this.$emit('cancel');
                })
                .catch((err) => {
                  this.parseErrors(err);
                });
            } else {
              this.addAdvertisement(requestBody)
                .then((response) => {
                  this.setNotification({
                    title: response.message,
                    type: 'success',
                  });
                  this.getUserAdvertisements({ userId: this.user.id });
                  this.resetAdvertisement(this.resetForm);
                  this.$emit('cancel');
                })
                .catch((err) => {
                  if (err.status === 422) {
                    this.setNotification({
                      title: err.data.errors.message,
                      type: 'error',
                    });
                  } else {
                    this.parseErrors(err.data.errors);
                  }
                });
            }
          }
        } catch (error) {
          this.setNotification({
            title: 'Error Occured while adding Event!',
            type: 'error',
          });
        }
      },

      parseErrors(errors: any) {
        Object.keys(errors).forEach((i: any) => {
          [this.errors[i]] = errors[i];
        });
      },
    },
  });
