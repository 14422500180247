import IUser from '@/interfaces/IUser';
import { State } from './index';

const mutations = {
  setUser(state: State, payload: IUser): void {
    state.user = payload;
  },
  setUsers(state: State, payload: Array<IUser>): void {
    state.users = payload;
  },
  setUsersMeta(state: State, payload: any): void {
    state.usersMeta = payload;
  },
  setUserPosts(state: State, userPosts: Array<IUser>): void {
    state.userPosts = userPosts;
  },
  setUserJobs(state: State, userJobs: Array<IUser>): void {
    state.userJobs = userJobs;
  },
  setUserEvents(state: State, userEvents: Array<IUser>): void {
    state.userEvents = userEvents;
  },
  setUserAdvertisements(state: State, userAdvertisements: Array<IUser>): void {
    state.userAdvertisements = userAdvertisements;
  },
  setLoggedInUser(state: State, payload: IUser): void {
    state.loggedInUser = payload;
  },
  setCountries(state: State, countries: Array<any>): void {
    state.countries = countries;
  },
};

export default mutations;
