
  import { defineComponent } from 'vue';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import { CalendarIcon } from '@heroicons/vue/solid';
  import { format } from 'date-fns';

  export default defineComponent({
    components: {
      CalendarIcon,
    },
    data() {
      return {
        errors: {} as Record<string, unknown>,
        restEventForm: {
          id: null,
          title: '',
          userId: '',
          description: '',
          venue: '',
          fromDate: format(new Date(), 'yyyy-MM-dd'),
          toDate: format(new Date(), 'yyyy-MM-dd'),
          image: '',
          link: '',
          time: '',
        },
        file: '',
      };
    },

    computed: {
      ...mapState('user', ['user']),
      ...mapState('event', ['event']),
    },
    unmounted() {
      this.resetEvent(this.restEventForm);
    },

    mounted() {
      if (this.event.id) {
        this.event.fromDate = format(
          new Date(this.event.fromDate),
          'yyyy-MM-dd',
        );
        this.event.toDate = format(new Date(this.event.toDate), 'yyyy-MM-dd');
      }
    },
    methods: {
      ...mapActions('event', ['addEvent', 'updateEvent']),
      ...mapActions('user', ['getUserEvents']),
      ...mapMutations('event', ['setEvent', 'resetEvent']),
      ...mapMutations('notification', ['setNotification']),
      uploadImage(event: any) {
        this.file = event.target.files[0];
      },

      validateForm() {
        this.errors = {};
        if (
          this.event.title === '' ||
          this.event.title === null ||
          this.event.title === undefined
        ) {
          this.errors.title = 'Title is required!';
        }
        if (
          this.event.venue === '' ||
          this.event.venue === null ||
          this.event.title === undefined
        ) {
          this.errors.venue = 'Venue is required!';
        }
        if (
          this.event.time === '' ||
          this.event.time === null ||
          this.event.time === undefined
        ) {
          this.errors.time = 'Time is required!';
        }
        if (
          this.event.fromDate === '' ||
          this.event.fromDate === null ||
          this.event.fromDate === undefined
        ) {
          this.errors.fromDate = 'From date is required!';
        }
        if (
          this.event.toDate === '' ||
          this.event.toDate === null ||
          this.event.toDate === undefined
        ) {
          this.errors.toDate = 'To date is required!';
        }

        const hasErrors = Boolean(Object.keys(this.errors).length);

        return hasErrors;
      },
      submit() {
        try {
          const hasErrors = this.validateForm();
          if (!hasErrors) {
            this.event.userId = this.user.id;
            if (this.file) {
              this.event.image = this.file;
            }
            if (this.event.id) {
              this.updateEvent(this.event)
                .then((response) => {
                  this.setNotification({
                    title: response.message,
                    type: 'success',
                  });
                  this.getUserEvents({ userId: this.user.id });
                  this.resetEvent(this.restEventForm);
                  this.$emit('cancel');
                })
                .catch((err) => {
                  this.parseErrors(err);
                });
            } else {
              this.addEvent(this.event)
                .then((response) => {
                  this.setNotification({
                    title: response.message,
                    type: 'success',
                  });
                  this.getUserEvents({ userId: this.user.id });
                  this.resetEvent(this.restEventForm);
                  this.$emit('cancel');
                })
                .catch((err) => {
                  if (err.status === 422) {
                    this.setNotification({
                      title: err.data.errors.message,
                      type: 'error',
                    });
                  } else {
                    this.parseErrors(err.data.errors);
                  }
                });
            }
          }
        } catch (error) {
          this.setNotification({
            title: 'Error Occured while adding Event!',
            type: 'error',
          });
        }
      },

      parseErrors(errors: any) {
        Object.keys(errors).forEach((i: any) => {
          [this.errors[i]] = errors[i];
        });
      },
    },
  });
