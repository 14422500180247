import axios from '../../../middleware/Axios';
import { ActionContext } from 'vuex';
import { State } from './index';
import { RootState } from '@/store';
import IJobForm from '@/interfaces/IJob';
import camelToSnake from '@/utils/camelToSnake';
const actions = {
  getJobs(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const page = payload && payload.page ? payload.page : 1;
      const search = payload && payload.search ? payload.search : '';
      let query = `api/v1/jobs?page=${page}`;
      if (search) {
        query = `${query}&search=${search}`;
      }
      axios
        .get(query)
        .then((response) => {
          context.commit('setJobs', response.data.data);
          context.commit('setJobsMeta', response.data.meta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addJob(
    context: ActionContext<State, RootState>,
    payload: IJobForm,
  ): Promise<any> {
    const formData = new FormData();
    const requestBody = camelToSnake(payload);
    for (const key in requestBody) {
      if (requestBody[key]) {
        formData.append(key, requestBody[key]);
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/jobs', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  updateJob(
    context: ActionContext<State, RootState>,
    payload: IJobForm,
  ): Promise<any> {
    const formData = new FormData();
    const requestBody = camelToSnake(payload);
    for (const key in requestBody) {
      if (requestBody[key]) {
        formData.append(key, requestBody[key]);
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`api/v1/jobs/${payload.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data.errors);
        });
    });
  },

  // get job by job id
  getJob(
    context: ActionContext<State, RootState>,
    payload: number,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/jobs/${payload}`)
        .then((response) => {
          context.commit('setJob', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteJob(_: any, payload: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`api/v1/jobs/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
};

export default actions;
