import actions from "./actions";
import mutations from "./mutations";
import IPlan from "@/interfaces/IPlan";
const state = {
  plans: [],
};

const Plan = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default Plan;
export interface State {
  plans: Array<IPlan>;
}
