import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import IEvent from '@/interfaces/IEvent';
import { format } from 'date-fns';

const state = {
  events: [],
  userEvents: [],
  event: {
    fromDate: format(new Date(), 'yyyy-MM-dd'),
    toDate: format(new Date(), 'yyyy-MM-dd'),
  },
  eventMeta: {},
};

const Event = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default Event;

export interface State {
  userEvents: Array<IEvent>;
  event: IEvent;
  events: Array<IEvent>;
  eventMeta: any;
}
