
  import { defineComponent } from 'vue';
  import Main from './views/layout/Main.vue';
  import Notification from './components/Notification.vue';
  import { mapActions } from 'vuex';

  export default defineComponent({
    components: {
      Main,
      Notification,
    },
    mounted() {
      const user = localStorage.getItem('user');
      if (user) {
        this.getUser();
      }
    },
    methods: {
      ...mapActions('user', ['getUser']),
    },
  });
