
  import { defineComponent } from 'vue';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import { ref } from 'vue';
  import {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  } from '@headlessui/vue';
  const jobTypes = [{ name: 'offer' }, { name: 'wanted' }];
  export default defineComponent({
    components: {
      RadioGroup,
      RadioGroupLabel,
      RadioGroupOption,
    },
    data() {
      return {
        errors: {} as Record<string, unknown>,
        file: '',
      };
    },

    setup() {
      const selectedJobType = ref(jobTypes[0]);
      return {
        jobTypes,
        selectedJobType,
        resetJobForm: {
          id: null,
          companyName: '',
          userName: '',
          userProfileImage: '',
          title: '',
          location: '',
          type: '',
          createdAt: '',
          resumeFile: '',
          salary: '',
          time: '',
          description: '',
          skill: '',
        },
      };
    },

    computed: {
      ...mapState('user', ['user']),
      ...mapState('job', ['job']),
    },

    mounted() {
      if (this.job && this.job.id) {
        const setJobType = this.jobTypes.filter(
          (i) => i.name === this.job.type,
        );
        this.selectedJobType = setJobType[0];
      }
    },
    unmounted() {
      this.resetJob(this.resetJobForm);
    },

    methods: {
      ...mapActions('job', ['addJob', 'updateJob']),
      ...mapActions('user', ['getUserJobs']),
      ...mapMutations('job', ['resetJob']),
      ...mapMutations('notification', ['setNotification']),

      uploadImage(event: any) {
        this.file = event.target.files[0];
      },
      downloadURI(uri: any, name: string) {
        var link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },

      validateForm() {
        this.errors = {};
        if (
          this.job.title === '' ||
          this.job.title === null ||
          this.job.title === undefined
        ) {
          this.errors.title = 'Title is Required!';
        }
        if (
          this.job.skill === '' ||
          this.job.skill === null ||
          this.job.skill === undefined
        ) {
          this.errors.skill = 'Skill is Required!';
        }
        if (
          this.job.location === '' ||
          this.job.location === null ||
          this.job.location === undefined
        ) {
          this.errors.location = 'Location is Required!';
        }

        const hasErrors = Boolean(Object.keys(this.errors).length);

        return hasErrors;
      },
      submit() {
        try {
          const hasErrors = this.validateForm();
          if (!hasErrors) {
            this.job.userId = this.user.id;
            this.job.type = this.selectedJobType.name;
            const { resumeFile, ...rest } = this.job;
            const requestBody = {
              ...rest,
              ...(this.file && { resumeFile: this.file }),
              ...(!this.file && resumeFile && { resumeFile }),
            };
            if (this.job.id) {
              this.updateJob(requestBody)
                .then((response) => {
                  this.setNotification({
                    title: response.message,
                    type: 'success',
                  });
                  this.resetJob(this.resetJobForm);
                  this.getUserJobs({ userId: this.user.id });
                  this.$emit('cancel');
                })
                .catch((err) => {
                  this.parseErrors(err);
                });
            } else {
              this.addJob(requestBody)
                .then((response) => {
                  this.setNotification({
                    title: response.message,
                    type: 'success',
                  });
                  this.resetJob(this.resetJobForm);
                  this.getUserJobs({ userId: this.user.id });
                  this.$emit('cancel');
                })
                .catch((err) => {
                  if (err.status === 422) {
                    this.setNotification({
                      title: err.data.errors.message,
                      type: 'error',
                    });
                  } else {
                    this.parseErrors(err.data.errors);
                  }
                });
            }
          }
        } catch (error) {
          this.setNotification({
            title: 'Error Occured while adding Job!',
            type: 'error',
          });
        }
      },

      parseErrors(errors: any) {
        Object.keys(errors).forEach((i: any) => {
          [this.errors[i]] = errors[i];
        });
      },
    },
  });
