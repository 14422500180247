import IJob from '@/interfaces/IJob';
import { State } from '../job';

const mutations = {
  setJobs(state: State, jobs: Array<IJob>): void {
    state.jobs = jobs;
  },
  setJob(state: State, job: IJob): void {
    state.job = job;
  },
  resetJob(state: State, job: IJob): void {
    state.job = job;
  },
  setJobsMeta(state: State, payload: any): void {
    state.jobMeta = payload;
  },
};

export default mutations;
