import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";
import User from "./modules/user";
import Role from "./modules/role";
import Post from "./modules/post";
import Job from "./modules/job";
import Notification from "./modules/notification";
import Event from "./modules/event";
import Advertisement from "./modules/advertisement";
import SubscriptionPlans from "./modules/subscriptionPlan";
import Review from "./modules/review";
import Admin from "./modules/admin";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

export const key: InjectionKey<Store<RootState>> = Symbol();

export const store = createStore<RootState>({
  modules: {
    user: User,
    role: Role,
    post: Post,
    job: Job,
    notification: Notification,
    event: Event,
    advertisement: Advertisement,
    subscriptionPlans: SubscriptionPlans,
    review: Review,
    admin: Admin,
  },
});
