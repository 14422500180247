
  import { defineComponent } from '@vue/runtime-core';
  import { mapState } from 'vuex';
  import { capitalize } from '../../utils/camelToReadable';

  export default defineComponent({
    components: {},
    computed: {
      ...mapState('post', ['post', 'post']),
    },
    methods: {
      getFirstLetterCapital(value: string): string {
        return capitalize(value);
      },
      formatDate(date: Date) {
        return new Date(date).toISOString().split('T')[0];
      },
      getCertificateValue(value: boolean) {
        if (value === false) {
          return 'No';
        } else {
          return 'Yes';
        }
      },
    },
  });
