
  import { CheckIcon } from '@heroicons/vue/outline';
  import { defineComponent } from '@vue/runtime-core';
  // import { RadioGroup, RadioGroupOption } from "@headlessui/vue";
  const membershipPricing = [
    {
      title: 'Free',
      price: 0,
      frequency: '/year',
      description: 'The essentials to provide your best work for clients.',
      features: [
        '2 posts per month',
        '2 events per month',
        '1 job per month',
        'Needs Admin Approval for onboarding  ',
      ],
      mostPopular: false,
      type: 'membership',
    },
    {
      title: 'Professional',
      price: 60,
      frequency: '/year',
      description: 'A plan that scales with your rapidly growing business.',
      features: [
        '10 posts per month',
        '10 events per month',
        '10 job per month',
        'One classified post advertisement per annum',
        'One banner advertisement per annum',
        'Email Support',
        'No Admin Approval for onboarding',
      ],
      mostPopular: true,
      type: 'membership',
    },
    {
      title: 'Enterprise',
      price: 180,
      frequency: '/year',
      description: 'Dedicated support and infrastructure for your company.',
      features: [
        'Unlimited posts',
        'Unlimited events',
        'Unlimited jobs',
        'One classified post advertisement per month',
        'One banner advertisement per month',
        '8 hours Phone / Live Support',
        'No Admin Approval for onboarding',
      ],
      mostPopular: false,
      type: 'membership',
    },
  ];
  const advertisementPricing = [
    {
      title: 'Classified Post',
      price: 20,
      priceId: 'price_1LRANrFWESKmRsAACBhcJlcs',
      frequency: '',
      description: 'Highlight your post on home page for 7 days slot',
      features: [
        'Promote existing post',
        'Promote new post',
        'Multiple posts per month',
        'Change post',
      ],
      cta: 'Annual billing',
      mostPopular: false,
      type: 'advertising',
    },
    {
      title: 'Banner',
      priceId: 'price_1LRAOdFWESKmRsAA4ufZnhdW',
      price: 50,
      frequency: '',
      description: 'Promote your business on home page banner for 7 days slot',
      features: [
        'Customized title',
        'Customized image',
        'Customized call to action',
        'Promote your brand',
      ],
      cta: 'Annual billing',
      mostPopular: false,
      type: 'advertising',
    },
  ];

  export default defineComponent({
    components: {
      CheckIcon,
    },
    setup() {
      return {
        membershipPricing,
        advertisementPricing,
      };
    },
    data: () => {
      return {
        selectedType: 'membership',
      };
    },
    methods: {
      showMemberships() {
        this.selectedType = 'membership';
      },
      showAdvertising() {
        this.selectedType = 'advertising';
      },
    },
  });
