import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import IReview from "@/interfaces/IReview";

const state = {
  userReviews: [],
  review: {},
};

const Review = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default Review;
export interface State {
  review: IReview;
  userReviews: Array<IReview>;
}
