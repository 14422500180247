import PostList from '../views/admin/Posts.vue';
import JobList from '../views/admin/Jobs.vue';
import UserList from '../views/admin/Users.vue';
import EventList from '../views/admin/Events.vue';
import Dashboard from '../views/admin/Dashboard.vue';

const adminRoutes = [
  {
    path: '/admin-dashboard',
    component: Dashboard,
    meta: { isAdmin: true, name: 'Dashboard' },
  },
  {
    path: '/admin-posts',
    component: PostList,
    meta: { isAdmin: true, name: 'Posts' },
  },
  {
    path: '/admin-jobs',
    component: JobList,
    meta: { isAdmin: true, name: 'Jobs' },
  },
  {
    path: '/admin-users',
    component: UserList,
    meta: { isAdmin: true, name: 'Users' },
  },
  {
    path: '/admin-events',
    component: EventList,
    meta: { isAdmin: true, name: 'Events' },
  },
];

export default adminRoutes;
