import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import IUser from '../../../interfaces/IUser';
const state = {
  users: [],
  user: {},
  userPosts: [],
  userJobs: [],
  userReviews: [],
  userEvents: [],
  userAdvertisements: [],
  loggedInUser: {},
  usersMeta: {},
  countries: [],
};

const User = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default User;
export interface State {
  users: Array<IUser>;
  user: IUser;
  userPosts: Array<IUser>;
  userJobs: Array<IUser>;
  userEvents: Array<IUser>;
  userAdvertisements: Array<IUser>;
  loggedInUser: IUser;
  usersMeta: any;
  countries: Array<any>;
}
