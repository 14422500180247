
  import { defineComponent } from 'vue';
  import { mapActions, mapState, mapMutations } from 'vuex';
  import { toCapitalize } from '../../utils/camelToReadable';
  import Dropdown from '../../components/Dropdown.vue';
  import Modal from '../../components/Modal.vue';
  import PostForm from '../post/PostForm.vue';
  import { SearchIcon } from '@heroicons/vue/solid';
  import { Constants } from '../../constants/constants';
  import { ExclamationIcon } from '@heroicons/vue/outline';
  import { DialogTitle } from '@headlessui/vue';
  import { format } from 'date-fns';

  export default defineComponent({
    components: {
      Dropdown,
      Modal,
      PostForm,
      SearchIcon,
      DialogTitle,
      ExclamationIcon,
    },
    data() {
      return {
        abc: '',
        profile: {},
        editableProperty: '',
        readView: false,
        postFormModal: false,
        tableActions: [
          {
            label: 'Edit',
            action: 'edit',
            disabled: false,
          },
          {
            label: 'Delete',
            action: 'delete',
            disabled: false,
          },
        ],
        searchKeyword: '',
        messageForUserIfNoData: Constants.messageForUserIfNoData,
        showConfirmation: false,
        postToBeDeleted: '',
        remainingPosts: 0,
      };
    },
    computed: {
      ...mapState('user', ['user', 'userPosts']),
    },
    watch: {
      searchKeyword: {
        handler: function (value) {
          if (value.length > 2) {
            this.getUserPosts({ userId: this.user.id, search: value });
          }
          if (value.length === 0) {
            this.getUserPosts({ userId: this.user.id });
          }
        },
      },
    },

    mounted() {
      if (this.$route.name === 'View Profile') {
        this.readView = true;
      }
    },
    methods: {
      ...mapActions('user', ['getUserPosts', 'getUser']),
      ...mapActions('post', ['getPost', 'deletePost']),

      ...mapMutations('notification', ['setNotification']),
      getInitials(val: string) {
        return val
          .split(' ')
          .map((i) => i.charAt(0))
          .join('')
          .toUpperCase();
      },

      getFirstLetterCapital(value: string): string {
        if (value) {
          return toCapitalize(value);
        }
        return '';
      },
      formatDate(date: Date) {
        return format(new Date(date), 'yyyy-MM-dd');
      },
      async addPost(postId: string) {
        if (postId) {
          await this.getPost({
            id: postId,
          });
        }
        this.postFormModal = true;
      },
      confirmDelete(postId: string) {
        this.postToBeDeleted = postId;
        this.showConfirmation = true;
      },
      deleteSelectedPost() {
        this.deletePost(this.postToBeDeleted).then((response) => {
          this.getUserPosts({ userId: this.user.id });
          this.showConfirmation = false;
          this.setNotification({
            title: response.message,
            type: 'success',
          });
        });
      },
      closePostModal() {
        this.postFormModal = false;
      },
    },
  });
