
  import { defineComponent } from '@vue/runtime-core';
  import { camelCase } from 'lodash';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import { toCapitalize } from '../../utils/camelToReadable';
  import IUser from '@/interfaces/IUser';
  import Map from './Map.vue';
  import { InformationCircleIcon } from '@heroicons/vue/solid';

  export default defineComponent({
    data() {
      return {
        editableProperty: '',
        readView: false,
        isShowSearch: true,
        showAddressInformation: false,
      };
    },

    components: { Map, InformationCircleIcon },

    computed: {
      ...mapState('user', ['user']),

      basicFields: {
        get() {
          const userObject = this.user as IUser;
          const keys = (({
            name,
            contact,
            address,
            email,
            website,
            fax,
            facebookUrl,
            twitterUrl,
            linkedinUrl,
          }) => ({
            name,
            contact,
            address,
            email,
            website,
            fax,
            facebookUrl,
            twitterUrl,
            linkedinUrl,
          }))(userObject);
          return keys;
        },
        set(value) {
          console.log(value);
        },
      },
    },

    mounted() {
      if (this.$route.name === 'View Profile') {
        this.readView = true;
        this.isShowSearch = false;
      }
    },

    methods: {
      ...mapActions('user', ['updateUser']),
      ...mapMutations('notification', ['setNotification']),

      editField(field: string) {
        this.editableProperty = camelCase(field);
      },
      labelize(field: string) {
        return toCapitalize(field);
      },
      updateLatLong(address: string, lat: any, long: any) {
        this.updateUser({ address, map: { lat: lat, lng: long } })
          .then(() => {
            this.setNotification({
              title: 'Map Updated Successfully!',
              type: 'success',
            });
          })
          .catch(() => {
            this.setNotification({
              title: 'Error occured while updating map!',
              // subtitle: error.message,
              type: 'error',
            });
          });
      },

      update(key: string, field: unknown) {
        this.user[key] = field;
        this.updateUser({ [key]: field })
          .then(() => {
            this.setNotification({
              title: 'Profile Updated Successfully!',
              type: 'success',
            });
          })
          .catch((error) => {
            this.setNotification({
              title: 'Error Occured while updating profile!',
              subtitle: error.message,
              type: 'error',
            });
          });
        this.editableProperty = '';
      },
    },
  });
