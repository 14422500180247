
  import { defineComponent } from '@vue/runtime-core';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import Dropdown from '../components/Dropdown.vue';
  import { capitalize } from '../utils/camelToReadable';
  import Modal from '../components/Modal.vue';
  import JobDetails from './job/JobDetails.vue';
  import Auth from '../middleware/Auth';
  import { Constants } from '../constants/constants';
  import Pagination from '../components/Pagination.vue';
  import { FilterIcon, SearchIcon } from '@heroicons/vue/solid';
  import { reject } from 'lodash';

  export default defineComponent({
    components: {
      Dropdown,
      Modal,
      JobDetails,
      Pagination,
      FilterIcon,
      SearchIcon,
    },
    data: () => {
      return {
        showJobDetails: false,
        tableActions: [
          {
            label: 'View Details',
            action: 'edit',
            disabled: false,
          },
        ],
        messageForUserIfNoData: Constants.messageForUserIfNoData,
        searchKeyword: '',
        showFilters: false,
      };
    },
    computed: {
      ...mapState('job', ['jobs', 'jobMeta']),
    },
    watch: {
      searchKeyword: {
        handler: function (value) {
          if (value.length > 2) {
            this.getJobs({ search: value });
          }
          if (value.length === 0) {
            this.getJobs();
          }
        },
      },
    },
    mounted() {
      this.getJobs();
    },
    methods: {
      ...mapActions('job', ['getJobs', 'getJob']),
      ...mapMutations('notification', ['setNotification']),
      viewDetails(jobId: string) {
        const loggedInUser = Auth.getUser();
        if (loggedInUser && loggedInUser.id) {
          this.getJob(jobId)
            .then(() => {
              this.showJobDetails = true;
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          this.setNotification({
            title: 'Please Sign In!',
            type: 'danger',
          });
        }
      },
      getFirstLetterCapital(value: string): string {
        return capitalize(value);
      },
      formatDate(date: Date) {
        return new Date(date).toISOString().split('T')[0];
      },
      getPaginatedJobs(page: number) {
        this.getJobs({ page });
      },
    },
  });
