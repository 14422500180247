
  import { defineComponent } from '@vue/runtime-core';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import Dropdown from '../components/Dropdown.vue';
  import Auth from '../middleware/Auth';
  import Pagination from '../components/Pagination.vue';
  import { Constants } from '../constants/constants';
  import { FilterIcon, SearchIcon } from '@heroicons/vue/solid';
  export default defineComponent({
    data: function () {
      return {
        tableActions: [
          {
            label: 'Check Profile',
            action: 'checkProfile',
            disabled: false,
          },
        ],
        loggedInUser: {},
        messageForUserIfNoData: Constants.messageForUserIfNoData,
        showFilters: false,
        searchKeyword: '',
      };
    },
    components: {
      Dropdown,
      Pagination,
      FilterIcon,
      SearchIcon,
    },
    computed: {
      ...mapState('user', ['users', 'user', 'usersMeta']),
    },
    watch: {
      searchKeyword: {
        handler: function (value) {
          if (value.length > 2) {
            this.getUsers({ search: value });
          }
          if (value.length === 0) {
            this.getUsers();
          }
        },
      },
    },
    mounted() {
      if (this.$route.query && this.$route.query.category) {
        this.getSortedMembers({ category: this.$route.query.category });
      } else {
        this.getUsers();
      }

      this.loggedInUser = Auth.getUser();
    },
    methods: {
      ...mapActions('user', ['getUsers', 'getUserById', 'getSortedMembers']),
      ...mapMutations('notification', ['setNotification']),

      checkProfile(userId: string) {
        const loggedInUser = Auth.getUser();
        if (loggedInUser && loggedInUser.id) {
          this.getUserById(userId).then(() => {
            this.$router.push(`/view-profile/${userId}`);
          });
        } else {
          this.setNotification({
            title: 'Please Sign In!',
            type: 'danger',
          });
        }
      },
      getPaginatedUsers(page: number) {
        if (this.$route.query && this.$route.query.category) {
          this.getSortedMembers({ category: this.$route.query.category, page });
        } else {
          this.getUsers({ page: page });
        }
      },
      showCategoriesName(categories: any) {
        let names: any = [];
        categories.forEach((i: any, index: number) => {
          if (index < 2) {
            names.push(i.name);
          }
          i;
        });
        if (categories.length > 2) {
          names.push('etc');
        }
        return names.toString();
      },
    },
  });
