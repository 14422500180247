import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "py-1" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createBlock(_component_Menu, {
    as: "div",
    class: "relative inline-block text-left"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_MenuButton, { class: "inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.title) + " ", 1),
            _createVNode(_component_ChevronDownIcon, {
              class: "w-5 h-5 ml-2 -mr-1",
              "aria-hidden": "true"
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_Transition, {
        "enter-active-class": "transition duration-100 ease-out",
        "enter-from-class": "transform scale-95 opacity-0",
        "enter-to-class": "transform scale-100 opacity-100",
        "leave-active-class": "transition duration-75 ease-in",
        "leave-from-class": "transform scale-100 opacity-100",
        "leave-to-class": "transform scale-95 opacity-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MenuItems, { class: "absolute sm:right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menus, (item, index) => {
                  return (_openBlock(), _createBlock(_component_MenuItem, { key: index }, {
                    default: _withCtx(({ active }) => [
                      _createElementVNode("a", {
                        onClick: ($event: any) => (_ctx.$emit(item.action)),
                        class: _normalizeClass([
                active && !item.disabled
                  ? 'bg-indigo-100 text-gray-900'
                  : item.disabled
                  ? 'bg-gray-100'
                  : 'text-gray-700',
                'block px-4 py-2 text-sm cursor-pointer',
              ])
                      }, _toDisplayString(item.label), 11, _hoisted_2)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}