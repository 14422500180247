<template>
  <div>
    <!-- Hero card -->
    <div class="relative mt-10">
      <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-50" />
      <div class="mx-auto sm:px-6 lg:px-8">
        <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
          <div class="absolute inset-0">
            <img
              class="object-cover w-full h-full"
              src="../assets/images/about_us.jpeg"
              alt="People working on laptops"
            />
            <div class="absolute inset-0 bg-indigo-400 mix-blend-multiply" />
          </div>
          <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1
              class="text-4xl font-extrabold tracking-tight text-center sm:text-5xl lg:text-6xl"
            >
              <span class="block text-white">It's not about us</span>
              <span class="block text-indigo-200">It's about "All" of us</span>
            </h1>
            <p
              class="max-w-lg mx-auto mt-6 text-xl text-center text-indigo-200 sm:max-w-3xl"
            >
              Piling Network is a professional networking platform for worldwide
              community that is built for us to help & manage the future of
              value supply chain in piling & shoring industry.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden bg-gray-50">
      <div class="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <svg
          class="absolute top-0 transform -translate-x-1/2 left-full -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                class="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>
        <div class="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div class="lg:col-span-1">
            <h2
              class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
            >
              Our Mission
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              This industry is truly backbone of our economy but overlooked and
              undervalued and our mission is to change this.
            </p>
          </div>
          <dl
            class="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2"
          >
            <div v-for="mission in missions" :key="mission.name">
              <dt>
                <div
                  class="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md"
                >
                  <component
                    :is="mission.icon"
                    class="w-6 h-6"
                    aria-hidden="true"
                  />
                </div>
                <p class="mt-5 text-lg font-medium leading-6 text-gray-900">
                  {{ mission.name }}
                </p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                {{ mission.description }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="pl-8 pr-8 my-2 mt-10">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-14">
        <div class="relative">
          <h2
            class="text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl"
          >
            Who Should Be On Piling Network?
          </h2>
          <p class="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-500">
            Every Professional Connected To Civil Foundation And Construction
            Industry.
          </p>
        </div>
        <div
          class="grid max-w-lg gap-5 pb-4 mx-auto mt-12 lg:grid-cols-4 lg:max-w-none"
        >
          <div
            v-for="item in list"
            :key="item.name"
            class="flex flex-col overflow-hidden rounded-lg shadow-lg"
          >
            <div class="flex-shrink-0">
              <img
                class="object-cover w-full h-48"
                :src="`${item.image}`"
                alt=""
              />
            </div>
            <div class="flex flex-col justify-between flex-1 p-6 bg-white">
              <div class="flex-1">
                <span class="text-xl font-semibold text-gray-900">
                  {{ item.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden bg-gray-50">
      <div
        class="relative px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8"
      >
        <div class="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div class="lg:col-span-3">
            <h2
              class="text-base font-semibold tracking-wide text-indigo-600 uppercase"
            >
              Piling Network Benefits
            </h2>
            <p class="mt-2 text-3xl font-extrabold text-gray-900">
              All-in-one platform
            </p>
            <p class="mt-4 text-lg text-gray-500">
              What Do You Get Here That You Don't Anywhere?
            </p>
          </div>
        </div>

        <div class="mt-12 lg:mt-0 lg:col-span-2">
          <dl
            class="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8"
          >
            <div
              v-for="feature in features"
              :key="feature.name"
              class="relative"
            >
              <dt>
                <CheckIcon
                  class="absolute w-6 h-6 text-green-500"
                  aria-hidden="true"
                />
                <p
                  class="text-lg font-medium leading-6 text-gray-900 capitalize ml-9"
                >
                  {{ feature.name }}
                </p>
              </dt>
              <dd class="mt-2 text-base text-gray-500 ml-9">
                <span class="normal-case">
                  {{ feature.description }}
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    CheckIcon,
    GlobeAltIcon,
    LinkIcon,
    OfficeBuildingIcon,
    ChipIcon,
  } from '@heroicons/vue/outline';
  import { defineComponent } from '@vue/runtime-core';
  import student from '../assets/images/student.png';
  import professional from '../assets/images/business-professional.png';
  import freelancer from '../assets/images/freelancer.png';
  import designProfessional from '../assets/images/design-professional.png';
  import inspection from '../assets/images/inspection-company.png';
  import transportation from '../assets/images/logistic-and-transportation-company.png';
  import recruitement from '../assets/images/recruitement-company.png';
  import other from '../assets/images/other.png';

  export default defineComponent({
    components: {
      CheckIcon,
      GlobeAltIcon,
      LinkIcon,
      OfficeBuildingIcon,
      ChipIcon,
    },
    data() {
      return {
        features: [
          {
            name: 'Chain',
            description:
              'Discover complete supply value chain at one place. Find all the worldwide manufacturers, suppliers, services and professionals on one platform',
          },
          {
            name: 'Grow your Business',
            description:
              'Fuel your business & business relationships and skills by showcasing your company with the right information about your products, services, skills, knowledge, network, events, resume',
          },
          {
            name: 'Update Yourself',
            description:
              'Be up to date with the advance in piling technology. Be involved with projects outside your region. Interact with buyers from different parts of the world ',
          },
          {
            name: 'Online Service',
            description:
              'Want to inspect used sheet piles or use trench box, no worries, appoint an inspection services online. Sell & buy surplus, used piles and corelated material online from stocks around the world',
          },

          {
            name: 'Jobs',
            description:
              'Find & hire right professional talent worldwide. Get international job opportunities within industry. Work in your field as per your choice and country you would like to',
          },
          {
            name: 'Networking',
            description:
              'Meet other local professionals interested in networking. Network professionally and now personally + events + discussions. Meet other local professionals interested in networking',
          },
          {
            name: 'Registration Benefits',
            description:
              'Just a click away! Registered members get access to this data. Get direct access to consultants, contractors & owners',
          },
          {
            name: 'Notifications',
            description:
              'Identify people who can help you. Get notified in your target market worldwide',
          },
        ],
        missions: [
          {
            description:
              'We are on a mission to connect all the piling professionals worldwide.',
            icon: LinkIcon,
          },
          {
            description:
              'Everyone of us should have a shot at driving our own piling industry success.',
            icon: OfficeBuildingIcon,
          },
          {
            description:
              'To create a complete eco-system for piling and shoring solutions',
            icon: ChipIcon,
          },

          {
            description:
              'A social networking site for business people and professionals to connect',
            icon: GlobeAltIcon,
          },
        ],
        list: [
          {
            name: 'Student',
            image: student,
          },
          {
            name: 'Business Professionals',
            image: professional,
          },
          {
            name: 'Freelancers',
            image: freelancer,
          },
          {
            name: 'Design Professionals',
            image: designProfessional,
          },
          {
            name: 'Inspection Services',
            image: inspection,
          },
          {
            name: 'Logistics and Transportation',
            image: transportation,
          },
          {
            name: 'Recruitment Agencies',
            image: recruitement,
          },
          {
            name: 'Others',
            image: other,
          },
        ],
      };
    },
  });
</script>
