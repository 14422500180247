import IEvent from '@/interfaces/IEvent';
import IJob from '@/interfaces/IJob';
import IPost from '@/interfaces/IPost';
import IUser from '@/interfaces/IUser';
import { State } from './index';

const mutations = {
  setUsers(state: State, payload: Array<IUser>): void {
    state.users = payload;
  },
  setUserMeta(state: State, payload: any): void {
    state.userMeta = payload;
  },
  setPosts(state: State, payload: Array<IPost>): void {
    state.posts = payload;
  },
  setPostMeta(state: State, payload: any): void {
    state.postMeta = payload;
  },
  setJobMeta(state: State, payload: any): void {
    state.jobMeta = payload;
  },
  setEventMeta(state: State, payload: any): void {
    state.eventMeta = payload;
  },
  setJobs(state: State, payload: Array<IJob>): void {
    state.jobs = payload;
  },
  setEvents(state: State, payload: Array<IEvent>): void {
    state.events = payload;
  },
  setCounts(state: State, payload: any) {
    state.counts = payload;
  },
};

export default mutations;
