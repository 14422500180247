import { RootState } from '@/store';
import { ActionContext } from 'vuex';
import axios from '../../../middleware/Axios';
import { State } from './index';
import IRegistrationForm from '@/interfaces/IRegistrationForm';
import camelToSnake from '../../../utils/camelToSnake';
import { AxiosResponse } from 'axios';
import Auth from '../../../middleware/Auth';
const actions = {
  //to get countries
  getCountries(context: ActionContext<State, RootState>): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get('api/v1/countries')
        .then((response) => {
          resolve(response.data);
          context.commit('setCountries', response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  register(
    context: ActionContext<State, RootState>,
    payload: IRegistrationForm,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const convertedPayload = camelToSnake(payload);
      axios
        .post('api/v1/register', convertedPayload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  login(
    context: ActionContext<State, RootState>,
    payload: IRegistrationForm,
  ): Promise<any> {
    const auth = new Auth();
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/login', camelToSnake(payload))
        .then((response) => {
          auth.setToken(response.data.accessToken);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  //get payment intent for initializing stripe before registration
  getPaymentIntent(
    context: ActionContext<State, RootState>,
    payload: IRegistrationForm,
  ): Promise<any> {
    const convertedPayload = camelToSnake(payload);
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/payment/intent', convertedPayload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  getProRataPrice(
    context: ActionContext<State, RootState>,
    payload: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/pro-rata-price/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  //to get logged in user
  getUser(
    context: ActionContext<State, RootState>,
  ): Promise<void | AxiosResponse<any, any>> {
    const auth = new Auth();
    return axios
      .get('api/v1/me')
      .then((response) => {
        if (response) {
          context.commit('setUser', response.data.data);
          context.commit('setLoggedInUser', response.data.data);
          auth.setUser(response.data.data);
        }
      })
      .catch((error) => {
        return error.response;
      });
  },

  //patch api to update user
  updateUser(
    context: ActionContext<State, RootState>,
    payload: unknown,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch('api/v1/me', camelToSnake(payload))
        .then((response) => {
          context.commit('setUser', response.data.data);
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  //user list
  getUsers(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    const page = payload && payload.page ? payload.page : 1;
    const search = payload && payload.search ? payload.search : '';
    let query = `api/v1/users?page=${page}`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    return new Promise((_, reject) => {
      axios
        .get(query)
        .then((response) => {
          context.commit('setUsers', response.data.data);
          context.commit('setUsersMeta', response.data.meta);
        })
        .catch((err) => {
          reject(err.response.data.errors);
        });
    });
  },

  getSortedMembers(
    context: ActionContext<State, RootState>,
    payload: any,
  ): any {
    const page = payload && payload.page ? payload.page : 1;
    const query = new URL(
      `${process.env.VUE_APP_API_URL}/api/v1/sorted/members?page=${page}`,
    );
    Object.keys(payload).forEach((i) => {
      query.searchParams.append(i, payload[i]);
    });
    return new Promise((_, reject) => {
      axios
        .get(query.toString())
        .then((response) => {
          context.commit('setUsers', response.data.data);
          context.commit('setUsersMeta', response.data.meta);
        })
        .catch((err) => {
          reject(err.response.data.errors);
        });
    });
  },

  // get user posts
  getUserPosts(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    const page = payload && payload.page ? payload.page : 1;
    const search = payload && payload.search ? payload.search : '';
    let query = `api/v1/users/${payload.userId}/posts?page=${page}`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    if (payload.image) {
      query = `${query}&image='required'`;
    }
    return new Promise((_, reject) => {
      axios
        .get(query)
        .then((response) => {
          context.commit('setUserPosts', response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user jobs
  getUserJobs(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    const page = payload && payload.page ? payload.page : 1;
    const search = payload && payload.search ? payload.search : '';
    let query = `api/v1/users/${payload.userId}/jobs?page=${page}`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    return new Promise((_, reject) => {
      axios
        .get(query)
        .then((response) => {
          context.commit('setUserJobs', response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user events
  getUserEvents(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    const page = payload && payload.page ? payload.page : 1;
    const search = payload && payload.search ? payload.search : '';
    let query = `api/v1/users/${payload.userId}/events?page=${page}`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    return new Promise((_, reject) => {
      axios
        .get(query)
        .then((response) => {
          context.commit('setUserEvents', response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user by id
  getUserById(
    context: ActionContext<State, RootState>,
    payload: number,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/user/${payload}`)
        .then((response) => {
          context.commit('setUser', response.data.data);
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  // get user ads
  getUserAdvertisements(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    const page = payload && payload.page ? payload.page : 1;
    const search = payload && payload.search ? payload.search : '';
    let query = `api/v1/users/${payload.userId}/advertisements?page=${page}`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    return new Promise((_, reject) => {
      axios
        .get(query)
        .then((response) => {
          context.commit('setUserAdvertisements', response.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  // upload profile image
  uploadProfileImage(
    context: ActionContext<State, RootState>,
    payload: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('image', payload);
      axios
        .post(`api/v1/upload/profile-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  uploadCoverImage(
    context: ActionContext<State, RootState>,
    payload: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('image', payload);
      axios
        .post(`api/v1/upload/cover-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  //to send email through backend for reset password link
  forgotPassword(_: any, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/v1/forgot-password`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //to change password
  changePassword(_: any, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/v1/users/${payload.userId}/change-password`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  //to reset password in db
  resetPassword(_: any, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/reset-password`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //to delete categories of user
  deleteCategory(_: any, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`api/v1/users/${payload.userId}/category/${payload.categoryId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //to add categories of user
  storeCategory(_: any, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/v1/users/${payload.userId}/category/${payload.categoryId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createAdvertisementPaymentIntent(_: any, payload: any): any {
    return axios.post('api/v1/payment-intent/advertisement', payload);
  },

  getBoughtAdvertisementCount(_: any, userId: number) {
    return axios.get(`api/v1/users/${userId}/advertisement-payments`);
  }
};

export default actions;
