import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import IPost from '../../../interfaces/IPost';
import { format } from 'date-fns';

const state = {
  posts: [],
  post: {
    certificate: true,
    productionDate: format(new Date(), 'yyyy-MM-dd'),
  },
  postMeta: {},
};

const Post = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default Post;
export interface State {
  posts: Array<IPost>;
  post: IPost;
  postMeta: any;
}
