import IEvent from '@/interfaces/IEvent';
import { State } from './index';

const mutations = {
  setEvents(state: State, events: Array<IEvent>): void {
    state.events = events;
  },
  setEvent(state: State, event: IEvent): void {
    state.event = event;
  },
  resetEvent(state: State, event: IEvent): void {
    state.event = event;
  },
  setEventsMeta(state: State, payload: any): void {
    state.eventMeta = payload;
  },
};

export default mutations;
