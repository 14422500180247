
  import { defineComponent } from 'vue';
  import { mapActions, mapState } from 'vuex';
  import Dropdown from '../../components/Dropdown.vue';
  import Modal from '../../components/Modal.vue';
  import AdvertisementForm from '../advertisement/AdvertisementForm.vue';
  import { SearchIcon } from '@heroicons/vue/solid';
  import { Constants } from '../../constants/constants';
  import { isAfter } from 'date-fns/esm';

  export default defineComponent({
    components: {
      Dropdown,
      Modal,
      AdvertisementForm,
      SearchIcon,
    },
    data() {
      return {
        advertisementFormModal: false,
        tableActions: [
          {
            label: 'Edit',
            action: 'edit',
            disabled: false,
          },
        ],
        searchKeyword: '',
        messageForUserIfNoData: Constants.messageForUserIfNoData,
        remainingAdvertisements: 0,
        bannerCount: 0,
        postCount: 0,
      };
    },
    watch: {
      searchKeyword: {
        handler: function (value) {
          if (value.length > 2) {
            this.getUserAdvertisements({ userId: this.user.id, search: value });
          }
          if (value.length === 0) {
            this.getUserAdvertisements({ userId: this.user.id });
          }
        },
      },
    },
    computed: {
      ...mapState('user', ['user', 'userAdvertisements']),
    },

    mounted() {
      this.getUserAdvertisements({ userId: this.user.id });
      this.getRemainingAdvertisments();
      this.getBoughtAdvertisements();
    },
    methods: {
      ...mapActions('user', [
        'getUserAdvertisements',
        'getBoughtAdvertisementCount',
      ]),
      ...mapActions('advertisement', ['getAdvertisement']),

      formatDate(date: Date) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) {
          month = '0' + month;
        }
        if (day.length < 2) {
          day = '0' + day;
        }
        return [year, month, day].join('-');
      },
      addAdvertise() {
        this.advertisementFormModal = true;
      },
      async updateAdvertisement(id: number) {
        await this.getAdvertisement({
          advertisementId: id,
        });
        this.advertisementFormModal = true;
      },
      closeModal() {
        this.advertisementFormModal = false;
      },
      getRemainingAdvertisments() {
        if (this.userAdvertisements) {
          this.remainingAdvertisements =
            this.user.subscription.advertisementsLimit -
            this.userAdvertisements.length;
        }
      },
      checkIsAfter(date: any) {
        const result = isAfter(new Date(), date);
        return result;
      },
      getBoughtAdvertisements() {
        this.getBoughtAdvertisementCount(this.user.id).then((res) => {
          this.bannerCount = res.data.banners;
          this.postCount = res.data.posts;
        });
      },
    },
  });
