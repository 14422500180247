
  import { defineComponent } from '@vue/runtime-core';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import Dropdown from '../components/Dropdown.vue';
  import { capitalize } from '../utils/camelToReadable';
  import Modal from '../components/Modal.vue';
  import PostDetails from './post/PostDetails.vue';
  import Auth from '../middleware/Auth';
  import Pagination from '../components/Pagination.vue';
  import { Constants } from '../constants/constants';
  import { FilterIcon, SearchIcon } from '@heroicons/vue/solid';

  export default defineComponent({
    components: {
      Dropdown,
      Modal,
      PostDetails,
      Pagination,
      SearchIcon,
      FilterIcon,
    },
    data: () => {
      return {
        showPostDetails: false,
        tableActions: [
          {
            label: 'View Details',
            action: 'edit',
            disabled: false,
          },
        ],
        messageForUserIfNoData: Constants.messageForUserIfNoData,
        showFilters: false,
        searchKeyword: '',
      };
    },
    computed: {
      ...mapState('post', ['posts', 'postMeta']),
      ...mapState('user', ['user']),
    },

    watch: {
      searchKeyword: {
        handler: function (value) {
          if (value.length > 2) {
            this.getPosts({ search: value });
          }
          if (value.length === 0) {
            this.getPosts();
          }
        },
      },
    },

    mounted() {
      this.getPosts();
      if (this.$route.query && this.$route.query.id) {
        this.viewDetails(this.$route.query.id);
      }
    },
    methods: {
      ...mapActions('post', ['getPosts', 'getPost']),
      ...mapMutations('notification', ['setNotification']),

      viewDetails(postId: any) {
        const loggedInUser = Auth.getUser();
        if (loggedInUser && loggedInUser.id) {
          this.getPost({ id: postId })
            .then(() => {
              this.showPostDetails = true;
            })
            .catch(() => {
              this.setNotification({
                title: 'Please Sign In For More Details!',
                type: 'danger',
              });
            });
        } else {
          this.setNotification({
            title: 'Please Sign In For More Details!',
            type: 'danger',
          });
        }
      },

      formatDate(date: Date) {
        return new Date(date).toISOString().split('T')[0];
      },
      getPaginatedPosts(page: number) {
        this.getPosts({ page });
      },
    },
  });
