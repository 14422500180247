
  import { mapActions, mapState, mapMutations } from 'vuex';
  import { defineComponent } from 'vue';
  import MenuOption from '@/interfaces/MenuOption';
  import { capitalize } from '../../utils/camelToReadable';
  import { Constants } from '../../constants/constants';
  import { ExclamationIcon } from '@heroicons/vue/outline';
  import { DialogTitle } from '@headlessui/vue';
  import Modal from '../../components/Modal.vue';
  import Pagination from '../../components/Pagination.vue';

  export default defineComponent({
    components: {
      DialogTitle,
      ExclamationIcon,
      Modal,
      Pagination,
    },
    data() {
      return {
        isVisible: false,
        messageForUserIfNoData: Constants.messageForUserIfNoData,
        tableActions: [
          {
            label: 'Delete',
            action: 'delete',
            disabled: false,
          },
        ] as Array<MenuOption>,
        eventToBeDeleted: '',
        showConfirmation: false,
      };
    },
    computed: {
      ...mapState('admin', ['events', 'eventMeta']),
    },
    mounted() {
      this.getEvents();
    },
    methods: {
      ...mapActions('admin', ['getEvents', 'deleteEvent', 'restoreEvent']),
      ...mapMutations('notification', ['setNotification']),

      limitedDescription(text: string, length: number, suffix: any) {
        if (text) {
          if (text && text.length > length) {
            return text.substring(0, length) + suffix;
          } else {
            return `text`;
          }
        } else {
          return `No Description Added!`;
        }
      },

      getFirstLetterCapital(value: string): string {
        return capitalize(value);
      },
      formatDate(date: Date) {
        return new Date(date).toISOString().split('T')[0];
      },
      confirmDelete(eventId: string) {
        this.eventToBeDeleted = eventId;
        this.showConfirmation = true;
      },
      deleteSelectedEvent() {
        this.deleteEvent(this.eventToBeDeleted).then((response) => {
          this.getEvents();
          this.showConfirmation = false;
          this.setNotification({
            title: response.message,
            type: 'success',
          });
        });
      },
      restoreRecord(id: string) {
        this.restoreEvent(id).then((res) => {
          this.getEvents();
          this.setNotification({
            title: res.message,
            type: 'success',
          });
        });
      },
      getPaginatedEvents(page: number) {
        this.getEvents({ page });
      },
    },
  });
