import axios from '../../../middleware/Axios';
import { ActionContext } from 'vuex';
import { State } from './index';
import { RootState } from '@/store';
import IPost from '@/interfaces/IPost';
import camelToSnake from '../../../utils/camelToSnake';

const actions = {
  getPosts(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    const page = payload && payload.page ? payload.page : 1;
    const search = payload && payload.search ? payload.search : '';
    const perPage = payload && payload.perPage ? payload.perPage : 15;
    let query = `api/v1/posts?page=${page}&per_page=${perPage}`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    return new Promise((_, reject) => {
      axios
        .get(query)
        .then((response) => {
          context.commit('setPosts', response.data.data);
          context.commit('setPostMeta', response.data.meta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPost(
    context: ActionContext<State, RootState>,
    payload: IPost,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/posts/${payload.id}`)
        .then((response) => {
          context.commit('setPost', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  addPost(
    context: ActionContext<State, RootState>,
    payload: IPost,
  ): Promise<any> {
    const formData = new FormData();
    const requestBody = camelToSnake(payload);
    for (const key in requestBody) {
      if (typeof requestBody[key] === 'boolean') {
        formData.append(key, JSON.stringify(requestBody[key]));
      } else {
        formData.append(key, requestBody[key]);
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/posts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  updatePost(
    context: ActionContext<State, RootState>,
    payload: IPost,
  ): Promise<any> {
    const formData = new FormData();
    const requestBody = camelToSnake(payload);

    for (const key in requestBody) {
      if (typeof requestBody[key] === 'boolean') {
        formData.append(key, JSON.stringify(requestBody[key]));
      } else {
        formData.append(key, requestBody[key]);
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`api/v1/posts/${payload.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data.errors);
        });
    });
  },

  deletePost(_: any, payload: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`api/v1/posts/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default actions;
