<template>
  <div>
    <GoogleMap
      ref="mapRef"
      :api-key="mapConfig.apiKey"
      :center="mapConfig.center"
      :zoom="mapConfig.zoom"
      style="width: 100%; height: 500px"
    >
      <Marker :options="{ position: mapConfig.markerLocation }" />

      <CustomControl position="TOP_LEFT">
        <div class="relative mt-2.5">
          <div class="">
            <input
              v-if="showSearchBox"
              type="text"
              name="search"
              placeholder="Search..."
              id="search"
              class="w-full rounded-md"
              autocomplete="off"
              v-model="searchText"
              @keyup="onSearch"
            />
          </div>
          <div
            v-if="searchLoading || searchText || searchedAddresses.length"
            class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <!-- Loading -->
            <div v-if="searchLoading" class="flex justify-center">
              <svg
                class="w-5 h-5 text-black animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
            <!-- No Data Message -->
            <div
              v-if="searchText && !searchLoading && !searchedAddresses.length"
              class="py-1 pl-2 cursor-pointer hover:bg-gray-100"
            >
              No data
            </div>
            <!-- Results -->
            <div
              v-for="(address, index) in searchedAddresses"
              :key="'address_' + index"
              class="py-1 pl-2 cursor-pointer hover:bg-gray-100"
              @click="onSelectOfAddress(address)"
            >
              {{ address.formatted_address }}
            </div>
          </div>
        </div>
      </CustomControl>
    </GoogleMap>
  </div>
</template>

<script>
  import { ref, watch, reactive } from 'vue';
  import { GoogleMap, Marker, CustomControl } from 'vue3-google-map';

  export default {
    components: { GoogleMap, Marker, CustomControl },
    props: {
      selectedLatLng: Object,
      showSearchBox: Boolean,
    },
    data() {
      return {
        // map configs
        mapConfig: {
          apiKey: 'AIzaSyCadzU94_wnthbPRNpY4Ce66vATIJJGpdU',
          center: this.selectedLatLng,
          markerLocation: this.selectedLatLng,
          zoom: 15,
        },
        searchedAddresses: [],
        searchLoading: false,
        searchText: '',
      };
    },
    watch: {
      selectedLatLng(newLatLng) {
        this.mapConfig.center = newLatLng;
        this.mapConfig.markerLocation = newLatLng;
      },
      $props: {
        deep: true,
        handler() {
          // Handle changes to props here
        },
      },
    },
    mounted() {
      let isMapLoaded = false;
      let geocoder = null;

      watch(
        () => this.$refs.mapRef.ready,
        (ready) => {
          if (ready) {
            geocoder = new this.$refs.mapRef.api.Geocoder();
            isMapLoaded = true;
          }
        },
      );

      this.$watch(
        () => this.searchText,
        () => {
          this.searchedAddresses = [];
          if (isMapLoaded && this.searchText) {
            const geocodePayload = { address: this.searchText };
            this.searchLoading = true;

            geocoder
              .geocode(geocodePayload)
              .then((result) => {
                this.searchedAddresses = result.results;
                this.searchLoading = false;
              })
              .catch(() => {
                this.searchLoading = false;
              });
          }
        },
      );
    },
    methods: {
      onSelectOfAddress(address) {
        this.$refs.mapRef.map.panTo(address.geometry.location);
        this.mapConfig.markerLocation = address.geometry.location;
        this.$emit(
          'setLatLong',
          address.formatted_address,
          address.geometry.location.lat(),
          address.geometry.location.lng(),
        );

        this.searchedAddresses = [];
        this.searchText = '';
      },
    },
  };
</script>
