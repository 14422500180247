import axios from '../../../middleware/Axios';
import { RootState } from '@/store';
import { ActionContext } from 'vuex';
import { State } from './index';
const actions = {
  getSubscriptionPlans(context: ActionContext<State, RootState>): void {
    axios
      .get('/api/v1/subscription/plans')
      .then((response) => {
        context.commit('setPlans', response.data.data);
      })
      .catch((err) => {
        return err;
      });
  },

  getPaymentDetails(context: ActionContext<State, RootState>, payload: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/payments/${payload}`).then((response) => {
        resolve(response.data.data);
      }).catch(err => {
        return err;
      })
    });
  }
};
export default actions;
