<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <h2 class="text-xs font-medium tracking-wide text-gray-500 uppercase">
      Overview
    </h2>
    <ul
      class="grid grid-cols-1 gap-5 mt-3 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4"
    >
      <li class="flex col-span-1 rounded-md shadow-sm">
        <div
          class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white bg-purple-600 rounded-l-md"
        >
          US
        </div>
        <div
          class="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md"
        >
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <a
              class="font-medium text-gray-900 hover:text-gray-600"
              href="/admin/users"
              >Users</a
            >
            <p class="text-gray-500">
              {{ counts.activeUserCount }} Active Members
            </p>
            <p class="text-gray-500">
              {{ counts.inactiveUserCount }} Inactive Members
            </p>
          </div>
        </div>
      </li>
      <li class="flex col-span-1 rounded-md shadow-sm">
        <div
          class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white bg-pink-600 rounded-l-md"
        >
          PO
        </div>
        <div
          class="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md"
        >
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <a
              class="font-medium text-gray-900 hover:text-gray-600"
              href="/admin/orders"
              >Posts</a
            >
            <p class="text-gray-500">{{ counts.postCount }}</p>
          </div>
        </div>
      </li>
      <li class="flex col-span-1 rounded-md shadow-sm">
        <div
          class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white bg-green-600 rounded-l-md"
        >
          JO
        </div>
        <div
          class="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md"
        >
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <a
              class="font-medium text-gray-900 hover:text-gray-600"
              href="/admin/orders"
              >Jobs</a
            >
            <p class="text-gray-500">{{ counts.jobCount }}</p>
          </div>
        </div>
      </li>
      <li class="flex col-span-1 rounded-md shadow-sm">
        <div
          class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white bg-yellow-600 rounded-l-md"
        >
          EV
        </div>
        <div
          class="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md"
        >
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <a
              class="font-medium text-gray-900 hover:text-gray-600"
              href="/admin/orders"
              >Events</a
            >
            <p class="text-gray-500">{{ counts.eventCount }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  // import { DotsVerticalIcon } from '@heroicons/vue/solid';
  import { mapActions, mapState } from 'vuex';

  export default {
    components: {
      // DotsVerticalIcon,
    },

    computed: {
      ...mapState('admin', ['counts']),
    },
    mounted() {
      this.getCounts();
    },
    methods: {
      ...mapActions('admin', ['getCounts']),
    },
  };
</script>
