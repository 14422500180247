import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "min-h-screen" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "pb-5 border-b border-gray-200 sm:flex sm:items-right sm:justify-between" }
const _hoisted_4 = { class: "text-lg font-medium leading-6 text-gray-900" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "mt-3 sm:mt-0 sm:ml-4" }
const _hoisted_7 = {
  key: 0,
  type: "button",
  class: "inline-flex items-center justify-center px-4 py-2 ml-8 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm cursor-pointer hover:bg-indigo-700",
  "aria-expanded": "false"
}
const _hoisted_8 = {
  key: 0,
  class: "mt-3 sm:mt-0 sm:ml-4"
}
const _hoisted_9 = {
  key: 0,
  class: "my-1 overflow-x-auto sm:-mx-6 lg:-mx-8"
}
const _hoisted_10 = { class: "inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_11 = { class: "overflow-hidden border-b border-gray-200 shadow sm:rounded-lg" }
const _hoisted_12 = { class: "px-4 py-6 sm:px-6" }
const _hoisted_13 = { class: "space-y-8" }
const _hoisted_14 = { class: "flex space-x-3" }
const _hoisted_15 = { class: "flex-shrink-0" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "w-full" }
const _hoisted_18 = { class: "flex justify-between" }
const _hoisted_19 = { class: "text-sm" }
const _hoisted_20 = {
  href: "#",
  class: "font-medium text-gray-900"
}
const _hoisted_21 = ["onClick"]
const _hoisted_22 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_23 = {
  href: "#",
  class: "font-medium text-gray-900"
}
const _hoisted_24 = { class: "mt-1 text-sm text-gray-700" }
const _hoisted_25 = { class: "flex justify-between" }
const _hoisted_26 = { class: "mt-2 space-x-2 text-sm" }
const _hoisted_27 = { class: "font-medium text-gray-500" }
const _hoisted_28 = { class: "flex gap-2" }
const _hoisted_29 = {
  class: "relative bg-white rounded-full cursor-default",
  type: "button"
}
const _hoisted_30 = {
  key: 1,
  class: "px-2 py-3 text-sm font-medium text-center text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterIcon = _resolveComponent("FilterIcon")!
  const _component_dropdown = _resolveComponent("dropdown")!
  const _component_StarIcon = _resolveComponent("StarIcon")!
  const _component_ReviewForm = _resolveComponent("ReviewForm")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(this.selectedFilter), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (this.$route.name === 'View Profile')
              ? (_openBlock(), _createElementBlock("button", _hoisted_7, [
                  _createElementVNode("a", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addReview())),
                    class: "text-base font-medium text-white cursor-pointer"
                  }, " Add Review ")
                ]))
              : _createCommentVNode("", true)
          ]),
          (!this.$route.params.userId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_dropdown, {
                  title: "Filters",
                  menus: _ctx.filterOptions,
                  onGetReviewsForMe: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getReviewsForMe())),
                  onGetReviewsFromMe: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getReviewsFromMe()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FilterIcon, {
                      class: "w-5 h-5",
                      "aria-hidden": "true"
                    })
                  ]),
                  _: 1
                }, 8, ["menus"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.userReviews.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("ul", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userReviews, (review) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: review.id
                      }, [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("img", {
                              src: review.reviewFromProfileImage,
                              class: "w-10 h-10 rounded-full",
                              alt: ""
                            }, null, 8, _hoisted_16)
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("div", null, [
                                _createElementVNode("div", _hoisted_19, [
                                  _createElementVNode("a", _hoisted_20, _toDisplayString(review.reviewFromName), 1)
                                ]),
                                _createElementVNode("span", null, _toDisplayString(review.title), 1)
                              ]),
                              _createElementVNode("div", null, [
                                (_ctx.selectedFilter === 'Reviews From Me')
                                  ? (_openBlock(), _createElementBlock("button", {
                                      key: 0,
                                      type: "button",
                                      class: "inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                                      onClick: ($event: any) => (_ctx.editReview(review.id))
                                    }, " Edit ", 8, _hoisted_21))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            (_ctx.selectedFilter === 'Reviews From Me')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                  _createElementVNode("a", _hoisted_23, " Review To - " + _toDisplayString(review.reviewToName), 1)
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_24, [
                              _createElementVNode("p", null, _toDisplayString(review.description), 1)
                            ]),
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("div", _hoisted_26, [
                                _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.formatDate(review.createdAt)), 1)
                              ]),
                              _createElementVNode("div", _hoisted_28, [
                                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
                                  return _createElementVNode("div", { key: i }, [
                                    _createElementVNode("button", _hoisted_29, [
                                      _createVNode(_component_StarIcon, {
                                        class: _normalizeClass(
                                  i <= review.rating
                                    ? 'text-yellow-300 hover:text-yellow-400 h-5 w-5'
                                    : 'text-gray-300 hover:text-gray-400 h-5 w-5'
                                ),
                                        "aria-hidden": "true"
                                      }, null, 8, ["class"])
                                    ])
                                  ])
                                }), 64))
                              ])
                            ])
                          ])
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_30, " No Reviews Added ")),
      _createVNode(_component_modal, {
        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.reviewFormModal = false)),
        visibility: _ctx.reviewFormModal
      }, {
        content: _withCtx(() => [
          _createVNode(_component_ReviewForm, {
            onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeModal()))
          })
        ]),
        _: 1
      }, 8, ["visibility"])
    ])
  ]))
}