import IAdvertisement from '@/interfaces/IAdvertisement';
import IAdvertisementType from '@/interfaces/IAdvertisementType';
import { format } from 'date-fns';
import { State } from './index';

const mutations = {
  setAdvertisement(state: State, advertisement: IAdvertisement): void {
    state.advertisement = advertisement;
  },
  resetAdvertisement(state: State, advertisement: IAdvertisement): void {
    state.advertisement = advertisement;
  },
  setAdvertisementTypes(
    state: State,
    advertisementTypes: Array<IAdvertisementType>,
  ): void {
    state.advertisementTypes = advertisementTypes;
  },
  setBannerAdvertisements(state: State, payload: Array<IAdvertisement>): void {
    state.bannerAdvertisements = payload;
  },
  setPostAdvertisements(state: State, payload: any): void {
    state.postAdvertisements = payload;
  },
};

export default mutations;
