
  import { mapActions, mapState, mapMutations } from 'vuex';
  import { defineComponent } from 'vue';
  import MenuOption from '@/interfaces/MenuOption';
  import Dropdown from '../../components/Dropdown.vue';
  import { capitalize } from '../../utils/camelToReadable';
  import { Constants } from '../../constants/constants';
  import { ExclamationIcon } from '@heroicons/vue/outline';
  import { DialogTitle } from '@headlessui/vue';
  import Modal from '../../components/Modal.vue';
  import Pagination from '../../components/Pagination.vue';

  export default defineComponent({
    components: {
      Dropdown,
      DialogTitle,
      ExclamationIcon,
      Modal,
      Pagination,
    },
    data() {
      return {
        isVisible: false,
        tableActions: [
          {
            label: 'Delete',
            action: 'delete',
            disabled: false,
          },
        ] as Array<MenuOption>,
        messageForUserIfNoData: Constants.messageForUserIfNoData,
        postToBeDeleted: '',
        showConfirmation: false,
      };
    },
    computed: {
      ...mapState('admin', ['posts', 'postMeta']),
    },
    mounted() {
      this.getPosts();
    },
    methods: {
      ...mapActions('admin', ['getPosts', 'deletePost', 'restorePost']),
      ...mapMutations('notification', ['setNotification']),

      getFirstLetterCapital(value: string): string {
        return capitalize(value);
      },
      formatDate(date: Date) {
        return new Date(date).toISOString().split('T')[0];
      },
      getMenus(post: any) {
        if (post && post.deletedAt === null) {
          return [
            {
              label: 'Delete',
              action: 'delete',
              disabled: false,
            },
          ];
        } else {
          return [
            {
              label: 'Restore',
              action: 'restore',
              disabled: false,
            },
          ];
        }
      },
      confirmDelete(postId: string) {
        this.postToBeDeleted = postId;
        this.showConfirmation = true;
      },
      deleteSelectedPost() {
        this.deletePost(this.postToBeDeleted).then((response) => {
          this.getPosts();
          this.showConfirmation = false;
          this.setNotification({
            title: response.message,
            type: 'success',
          });
        });
      },
      restoreRecord(id: string) {
        this.restorePost(id).then((res) => {
          this.getPosts();
          this.setNotification({
            title: res.message,
            type: 'success',
          });
        });
      },
      getPaginatedPosts(page: number) {
        this.getPosts({ page });
      },
    },
  });
