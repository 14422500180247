import mutations from "./mutation";

const state = {
  notification: {
    title: "",
    subtitle: "",
    type: "",
    isVisible: false,
  },
};
const Notification = {
  namespaced: true,
  state,
  mutations,
};

export default Notification;
