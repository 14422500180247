import IReview from '@/interfaces/IReview';
import { State } from '../review';

const mutations = {
  setUserReviews(state: State, userReviews: Array<IReview>): void {
    state.userReviews = userReviews;
  },
  resetReview(state: State, review: IReview): void {
    state.review = review;
  },
  setReview(state: State, review: IReview): void {
    state.review = review;
  },
};

export default mutations;
