
  import { defineComponent } from 'vue';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import Auth from '../../middleware/Auth';
  import { StarIcon } from '@heroicons/vue/solid';
  export default defineComponent({
    components: {
      StarIcon,
    },
    data() {
      return {
        errors: {} as Record<string, unknown>,
        resetReviewForm: {
          id: null,
          title: '',
          userId: '',
          description: '',
          rating: '',
        },
      };
    },

    computed: {
      ...mapState('user', ['user']),
      ...mapState('review', ['review']),
    },

    unmounted() {
      this.resetReview(this.resetReviewForm);
    },

    methods: {
      ...mapActions('review', [
        'addReview',
        'updateReview',
        'getUserReviews',
        'getReviewsFromSelectedUser',
      ]),
      ...mapMutations('review', ['setReview', 'resetReview']),
      ...mapMutations('notification', ['setNotification']),
      addRating(n: number) {
        this.review.rating = n;
      },
      validateForm() {
        this.errors = {};
        if (this.review.title === '') {
          this.errors.title = 'Title is Required!';
        }
        if (this.review.description === '') {
          this.errors.description = 'Description is Required!';
        }
        const hasErrors = Boolean(Object.keys(this.errors).length);
        return hasErrors;
      },
      submit() {
        const hasErrors = this.validateForm();
        // get auth user
        const loggedInUser = Auth.getUser();
        //while seeing other's profile, user can only add review , can't edit
        if (this.$route.params.userId) {
          if (loggedInUser.id !== this.$route.params.userId) {
            if (!hasErrors) {
              this.review.reviewFrom = loggedInUser.id;
              this.review.reviewTo = this.$route.params.userId;
              this.addReview(this.review).then((response) => {
                this.setNotification({
                  title: response.message,
                  type: 'success',
                });
              });
              this.getUserReviews(this.$route.params.userId);
              this.resetReview(this.resetReviewForm);
              this.$emit('cancel');
            }
          } else {
            this.setNotification({
              title: "You can't Review Yourself!",
              type: 'error',
            });
          }
        } else {
          // logged in user can only edit reviews given by them from their profile
          if (this.review.id) {
            this.updateReview(this.review).then((response) => {
              this.setNotification({
                title: response.message,
                type: 'success',
              });
              this.getReviewsFromSelectedUser(loggedInUser.id);
              this.$emit('cancel');
            });
          }
        }
      },
    },
  });
