
  import { defineComponent } from 'vue';
  import { mapActions, mapMutations } from 'vuex';
  export default defineComponent({
    components: {},
    setup() {
      return {};
    },

    mounted() {
      if (this.$route.query.payment_intent) {
        const interval = setInterval(() => {
          this.getPaymentDetails(this.$route.query.payment_intent).then(
            (res) => {
              if (res.status === 'succeeded') {
                clearInterval(interval);
                this.$router.push('/edit-profile?tab=subscription');
                this.setNotification({
                  title: 'Payment Successful!',
                  subtitle: 'Plan upgraded successfully',
                  type: 'success',
                });
              }
            },
          );
        }, 5000);
      }
    },

    methods: {
      ...mapActions('subscriptionPlans', ['getPaymentDetails']),
      ...mapMutations('notification', ['setNotification']),
    },
  });
