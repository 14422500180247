
  import { defineComponent } from '@vue/runtime-core';
  import { camelCase } from 'lodash';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import { toCapitalize } from '../../utils/camelToReadable';
  import IUser from '@/interfaces/IUser';
  import { XIcon, InformationCircleIcon } from '@heroicons/vue/solid';

  export default defineComponent({
    components: {
      XIcon,
      InformationCircleIcon,
    },
    data() {
      return {
        editableProperty: '',
        readView: false,
        showCategories: false,
        selectedCategory: {} as Record<string, unknown>,
        showCategoryDropdown: true,
        showRemoveCategoryIcon: true,
        showEmbedTooltip: false,
      };
    },

    computed: {
      ...mapState('user', ['user']),
      ...mapState('role', ['categories']),
      advanceFields() {
        const userObject = this.user as IUser;
        const keys = (({
          companyName,
          designation,
          noOfEmployees,
          establishedYear,
          tradeMark,
          isoCert,
          directorName,
        }) => ({
          companyName,
          designation,
          noOfEmployees,
          establishedYear,
          tradeMark,
          isoCert,
          directorName,
        }))(userObject);
        return keys;
      },
    },

    watch: {
      user: {
        handler: function () {
          const div = document.getElementById(
            'responsiveVideoWrapper',
          ) as HTMLDivElement;
          if (div) {
            div.innerHTML = this.user.videoUrl;
          }
        },
        deep: true,
        immediate: true,
      },
    },

    mounted() {
      this.getAllRoleCategories();
      if (this.$route.name === 'View Profile') {
        this.readView = true;
        this.showCategoryDropdown = false;
        this.showRemoveCategoryIcon = false;
      }
      const div = document.getElementById(
        'responsiveVideoWrapper',
      ) as HTMLDivElement;
      if (div) {
        div.innerHTML = this.user.videoUrl;
      }
    },

    methods: {
      ...mapActions('user', [
        'updateUser',
        'deleteCategory',
        'getUser',
        'storeCategory',
      ]),
      ...mapActions('role', ['getAllRoleCategories']),
      ...mapMutations('notification', ['setNotification']),

      editField(field: string) {
        this.editableProperty = camelCase(field);
      },
      labelize(field: string) {
        return toCapitalize(field);
      },
      getInitials(val: string) {
        return val
          .split(' ')
          .map((i) => i.charAt(0))
          .join('')
          .toUpperCase();
      },
      addCategory() {
        this.storeCategory({
          categoryId: this.selectedCategory.id,
          userId: this.user.id,
        })
          .then((res) => {
            this.getUser();
            this.setNotification({
              title: res.data.message,
              type: 'success',
            });
          })
          .catch((err) => {
            this.setNotification({
              title: err.response.data.message,
              type: 'error',
            });
          });
      },
      removeCategory(category: any) {
        this.deleteCategory({ categoryId: category.id, userId: this.user.id })
          .then((res) => {
            this.getUser();
            this.setNotification({
              title: res.data.message,
              type: 'success',
            });
          })
          .catch((err) => {
            this.setNotification({
              title: err.response.data.message,
              type: 'error',
            });
          });
      },
      update(key: string, field: unknown) {
        if (key === 'videoUrl') {
          let parser = new DOMParser();
          let parsed = parser.parseFromString(
            this.user['videoUrl'],
            'text/html',
          );
          const [data] = parsed.getElementsByTagName('iframe');
          data.height = '100%';
          data.width = '100%';
          field = data.outerHTML;
        }

        this.user[key] = field;

        this.updateUser({ [key]: field })
          .then(() => {
            this.setNotification({
              title: 'Profile Updated Successfully!',
              type: 'success',
            });
          })
          .catch((error) => {
            this.setNotification({
              title: 'Error Occured while updating profile!',
              subtitle: error.message,
              type: 'error',
            });
          });
        this.editableProperty = '';
      },
    },
  });
