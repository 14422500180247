import { State } from './index';
import IPost from '@/interfaces/IPost';

const mutations = {
  setPosts(state: State, posts: Array<IPost>): void {
    state.posts = posts;
  },
  setPost(state: State, post: IPost): void {
    state.post = post;
  },
  resetPost(state: State, post: IPost): void {
    state.post = post;
  },
  setPostMeta(state: State, payload: any): void {
    state.postMeta = payload;
  },
};

export default mutations;
