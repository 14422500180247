
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
  import { ChevronDownIcon } from '@heroicons/vue/solid';
  import MenuOption from '../interfaces/MenuOption';

  export default {
    props: {
      menus: {
        type: Array as () => Array<MenuOption>,
      },
      title: {
        default: 'Actions',
        type: String,
      },
    },
    components: {
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      ChevronDownIcon,
    },
  };
