import axios from '../../../middleware/Axios';
import { ActionContext } from 'vuex';
import { State } from './index';
import { RootState } from '@/store';
import IEventForm from '@/interfaces/IEvent';
import camelToSnake from '../../../utils/camelToSnake';
import IUserEvent from '@/interfaces/IUserEvent';
import IEvent from '@/interfaces/IEvent';

const actions = {
  getEvents(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    return new Promise((_, reject) => {
      const page = payload && payload.page ? payload.page : 1;
      const search = payload && payload.search ? payload.search : '';
      let query = `api/v1/events?page=${page}`;
      if (search) {
        query = `${query}&search=${search}`;
      }
      axios
        .get(query)
        .then((response) => {
          context.commit('setEvents', response.data.data);
          context.commit('setEventsMeta', response.data.meta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addEvent(
    context: ActionContext<State, RootState>,
    payload: IEventForm,
  ): Promise<any> {
    const formData = new FormData();
    const requestBody = camelToSnake(payload);
    for (const key in requestBody) {
      if (requestBody[key]) {
        formData.append(key, requestBody[key]);
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/events', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  updateEvent(
    context: ActionContext<State, RootState>,
    payload: IEventForm,
  ): Promise<any> {
    const formData = new FormData();
    const requestBody = camelToSnake(payload);
    for (const key in requestBody) {
      if (requestBody[key]) {
        formData.append(key, requestBody[key]);
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`api/v1/event/${payload.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data.errors);
        });
    });
  },

  getEvent(
    context: ActionContext<State, RootState>,
    payload: IEvent,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/event/${payload.id}`)
        .then((response) => {
          context.commit('setEvent', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  deleteEvent(_: any, payload: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`api/v1/events/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
};

export default actions;
