
  import { defineComponent } from '@vue/runtime-core';
  import { mapActions, mapMutations } from 'vuex';
  import Modal from '../components/Modal.vue';
  import { EyeOffIcon, EyeIcon } from '@heroicons/vue/solid';

  export default defineComponent({
    data: () => {
      return {
        errors: {} as Record<string, unknown>,
        email: '',
        password: '',
        showSubscriptionTab: false as boolean,
        showForgotPasswordModal: false as boolean,
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        wrongConfirmPassword: false as boolean,
        emailAddress: '', //in forgot password form
        showPassword: false,
      };
    },
    components: { Modal, EyeOffIcon, EyeIcon },
    mounted() {
      if (this.$route.query.isRegistration) {
        this.showSubscriptionTab = true;
      }
    },
    methods: {
      ...mapActions('user', ['login', 'getUser', 'forgotPassword']),
      ...mapMutations('notification', ['setNotification']),

      async signIn() {
        const hasErrors = this.validateForm();
        if (!hasErrors) {
          try {
            await this.login({ email: this.email, password: this.password });
            await this.getUser();
            this.setNotification({
              title: 'Logged In Successfully!',
              subtitle: 'Welcome',
              type: 'success',
            });

            this.$router.push({
              path: '/edit-profile',
              query: { activateSubscriptionTab: `${this.showSubscriptionTab}` },
            });
          } catch (error) {
            this.setNotification({
              title: 'Error Occured while login!',
              type: 'error',
            });
          }
        }
      },
      validateForm() {
        this.errors = {};
        if (this.email === '') {
          this.errors.email = 'Email is required!';
        }
        if (this.password === '') {
          this.errors.password = 'Password is required!';
        }
        const hasErrors = Boolean(Object.keys(this.errors).length);
        return hasErrors;
      },
      goToRegistration() {
        this.$router.push('/registration');
      },
      openForgotPaasswordModal() {
        this.showForgotPasswordModal = true;
      },

      sendPasswordResetLink() {
        this.forgotPassword({ email: this.emailAddress }).then((response) => {
          this.showForgotPasswordModal = false;
          this.setNotification({
            title: response.data.message,
            type: 'success',
          });
        });
      },
    },
  });
