import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import IJob from '@/interfaces/IJob';

const state = {
  jobs: [],
  job: {},
  jobMeta: {},
};

const Job = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default Job;
export interface State {
  jobs: Array<IJob>;
  job: IJob;
  jobMeta: any;
}
