import IAdmin from '@/interfaces/IAdmin';
import { RootState } from '@/store';
import camelToSnake from '@/utils/camelToSnake';
// import axios from "axios";
import axios from '../../../middleware/Axios';
import { ActionContext } from 'vuex';
import { State } from './index';

const actions = {
  //get counts
  getCounts(context: ActionContext<State, RootState>): Promise<any> {
    return new Promise((_, reject) => {
      axios
        .get(`api/v1/admin/counts`)
        .then((response) => {
          context.commit('setCounts', response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  //user list
  getUsers(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    const page = payload && payload.page ? payload.page : 1;
    const search = payload && payload.search ? payload.search : '';
    let query = `api/v1/admin/users?page=${page}&per_page=20`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    return new Promise((_, reject) => {
      axios
        .get(query)
        .then((response) => {
          context.commit('setUsers', response.data.data);
          context.commit('setUserMeta', response.data.meta);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  //patch api to update user
  updateUser(
    context: ActionContext<State, RootState>,
    payload: IAdmin,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`api/v1/admin/user/${payload.userId}`, camelToSnake(payload))
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  /*-----------------------------Post Apis-------------------------------*/

  //post list
  getPosts(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    const page = payload && payload.page ? payload.page : 1;
    const search = payload && payload.search ? payload.search : '';
    let query = `api/v1/admin/posts?page=${page}&per_page=20`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    return new Promise((_, reject) => {
      axios
        .get(query)
        .then((response) => {
          context.commit('setPosts', response.data.data);
          context.commit('setPostMeta', response.data.meta);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  deletePost(_: any, payload: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`api/v1/admin/posts/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  restorePost(_: any, payload: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/admin/posts/restore/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  /*-----------------------------Job Apis-------------------------------*/

  //job list
  getJobs(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    const page = payload && payload.page ? payload.page : 1;
    const search = payload && payload.search ? payload.search : '';
    let query = `api/v1/admin/jobs?page=${page}&per_page=20`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    return new Promise((_, reject) => {
      axios
        .get(query)
        .then((response) => {
          context.commit('setJobs', response.data.data);
          context.commit('setJobMeta', response.data.meta);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  deleteJob(_: any, payload: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`api/v1/admin/jobs/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  restoreJob(_: any, payload: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/admin/jobs/restore/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  /*-----------------------------Event Apis-------------------------------*/

  //event list
  getEvents(
    context: ActionContext<State, RootState>,
    payload: any,
  ): Promise<any> {
    const page = payload && payload.page ? payload.page : 1;
    const search = payload && payload.search ? payload.search : '';
    let query = `api/v1/admin/events?page=${page}&per_page=20`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    return new Promise((_, reject) => {
      axios
        .get(query)
        .then((response) => {
          context.commit('setEvents', response.data.data);
          context.commit('setEventMeta', response.data.meta);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  deleteEvent(_: any, payload: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`api/v1/admin/events/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  restoreEvent(_: any, payload: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/admin/events/restore/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
};

export default actions;
