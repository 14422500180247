
  import { defineComponent } from '@vue/runtime-core';
  import { mapActions, mapMutations } from 'vuex';
  import Auth from '../middleware/Auth';
  import { EyeOffIcon, EyeIcon } from '@heroicons/vue/solid';

  export default defineComponent({
    data() {
      return {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        wrongConfirmPassword: false as boolean,
        errors: {} as Record<string, unknown>,
        showCurrentPassword: false,
        showNewPassword: false,
      };
    },
    components: { EyeOffIcon, EyeIcon },
    methods: {
      ...mapActions('user', ['changePassword']),
      ...mapMutations('notification', ['setNotification']),

      validateConfirmPassword() {
        if (this.newPassword != this.confirmPassword) {
          this.wrongConfirmPassword = true;
          this.errors.confirmPassword = 'Password Mismatched!';
        } else {
          this.wrongConfirmPassword = false;
          this.errors.confirmPassword = null;
        }
      },
      validateRequest() {
        this.errors = {};
        if (this.currentPassword === '') {
          this.errors.currentPassword = 'Current Password is Required!';
        }
        if (this.newPassword === '') {
          this.errors.newPassword = 'New Password is Required!';
        }
        if (this.confirmPassword === '') {
          this.errors.confirmPassword = 'Confirm Password is Required!';
        }
        const hasErrors = Boolean(Object.keys(this.errors).length);
        return hasErrors;
      },
      submit() {
        const loggedInUser = Auth.getUser();

        this.validateRequest();
        this.changePassword({
          current_password: this.currentPassword,
          new_password: this.confirmPassword,
          userId: loggedInUser.id,
        })
          .then((res: any) => {
            this.setNotification({
              title: res.message,
              type: 'success',
            });
            this.$router.push('/edit-profile');
          })
          .catch((err) => {
            this.setNotification({
              title: err.message,
              type: 'error',
            });
          });
      },
    },
  });
