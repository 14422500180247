import actions from './actions';
import mutations from './mutations';
import IRole from '@/interfaces/IRole';
import IRoleCategory from '@/interfaces/IRoleCategory';
const state = {
  roles: [],
  roleCategories: [],
  categories: [],
};

const Role = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default Role;
export interface State {
  roles: Array<IRole>;
  roleCategories: Array<IRoleCategory>;
  categories: Array<IRoleCategory>;
}
