
  import { defineComponent } from 'vue';
  import { mapActions, mapState } from 'vuex';
  import Dropdown from '../../components/Dropdown.vue';
  import Modal from '../../components/Modal.vue';
  import EventForm from '../event/EventForm.vue';
  import { SearchIcon } from '@heroicons/vue/solid';
  import { Constants } from '../../constants/constants';
  import { format } from 'date-fns';

  export default defineComponent({
    components: {
      Dropdown,
      Modal,
      EventForm,
      SearchIcon,
    },
    data() {
      return {
        abc: '',
        profile: {},
        editableProperty: '',
        readView: false,
        eventFormModal: false,
        tableActions: [
          {
            label: 'Edit',
            action: 'edit',
            disabled: false,
          },
        ],
        searchKeyword: '',
        messageForUserIfNoData: Constants.messageForUserIfNoData,
      };
    },
    watch: {
      searchKeyword: {
        handler: function (value) {
          if (value.length > 2) {
            this.getUserEvents({ userId: this.user.id, search: value });
          }
          if (value.length === 0) {
            this.getUserEvents({ userId: this.user.id });
          }
        },
      },
    },
    computed: {
      ...mapState('user', ['user', 'userEvents']),
    },

    mounted() {
      if (this.$route.name === 'View Profile') {
        this.readView = true;
      }
    },
    methods: {
      ...mapActions('user', ['getUserEvents']),
      ...mapActions('event', ['getEvent']),

      formatDate(date: Date) {
        return format(new Date(date), 'yyyy-MM-dd');
      },
      async addEvent(eventId: string) {
        if (eventId) {
          await this.getEvent({
            id: eventId,
          });
        }
        this.eventFormModal = true;
      },
      closeEventModal() {
        this.eventFormModal = false;
      },
    },
  });
