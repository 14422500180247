export function toCapitalize(name) {
  const words = name.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(' ');
}

export function capitalize(word) {
  if (word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  } else {
    return '';
  }
}
