
  import { defineComponent } from '@vue/runtime-core';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import Modal from '../components/Modal.vue';
  import EventDetails from './event/EventDetails.vue';
  import Auth from '../middleware/Auth';
  import { FilterIcon, SearchIcon } from '@heroicons/vue/solid';
  import Pagination from '../components/Pagination.vue';

  export default defineComponent({
    data: () => {
      return {
        href: '#',
        eventDetailsModal: false,
        searchKeyword: '',
        showFilters: false,
        Pagination,
      };
    },
    components: {
      EventDetails,
      Modal,
      FilterIcon,
      SearchIcon,
    },
    watch: {
      searchKeyword: {
        handler: function (value) {
          if (value.length > 2) {
            this.getEvents({ search: value });
          }
          if (value.length === 0) {
            this.getEvents();
          }
        },
      },
    },
    computed: {
      ...mapState('event', ['events', 'eventMeta']),
    },
    filters: {},
    mounted() {
      this.getEvents();
    },
    methods: {
      ...mapActions('event', ['getEvents', 'getEvent']),
      ...mapMutations('notification', ['setNotification']),
      formatDate(date: Date) {
        return new Date(date).toISOString().split('T')[0];
      },
      limitedDescription(text: string, length: number, suffix: any) {
        if (text) {
          if (text && text.length > length) {
            return text.substring(0, length) + suffix;
          } else {
            return `text`;
          }
        } else {
          return `No Description Added!`;
        }
      },
      openview(eventId: string) {
        const loggedInUser = Auth.getUser();
        if (loggedInUser && loggedInUser.id) {
          this.getEvent({ id: eventId })
            .then(() => {
              this.eventDetailsModal = true;
            })
            .catch(() => {
              this.setNotification({
                title: 'Please Sign In!',
                type: 'danger',
              });
            });
        } else {
          this.setNotification({
            title: 'Please Sign In!',
            type: 'danger',
          });
        }
      },
      getPaginatedEvents(page: number) {
        this.getEvents({ page });
      },
    },
  });
